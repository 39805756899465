<ng-container>
  <div 
      [showIfHasCapability]="[
        {
          capabilityString: 'CanAccessAdminWing',
          type: 'client'
        }
      ]"
      class="content85percent capabilities-margin"
    >
    <div class="page-header-bar">
      <div class="float-start">
        <h1>Admin: Manage Users</h1>
        <p>Use the search function below to find specific users. Once located, you have the ability to manage them by adjusting their role type, adding or removing roles, and even creating new users or deleting existing ones.</p>
      </div>
    </div>
    <form [formGroup]="searchForm" class="clrsgn-form search-form">
      <div class="searchFormBody row">
        <span class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label class="clrsgn-label">Organization</mat-label>
            <mat-select formControlName="client" data-cy="organizationDropdown">
              <mat-option
                *ngFor="let client of (currentUserClientAdminAccounts$ | async)?.clients"
                [value]="client.clientId"
                (click)="setClient(client)"
              >
                {{ client.clientName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label class="clrsgn-label">Search Email</mat-label>
            <input
              matInput
              class="search-input"
              formControlName="searchEmail"
              type="text"
              id="searchEmail"
              (keyup)="handleKeyUp($event)"
            />
            <button class="clrsgn-btn-default float-end search-button" type="button" (click)="setSearchText()">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>           
          </mat-form-field>
        </span>
        <span class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label class="clrsgn-label">Search Name</mat-label>
            <input
              matInput
              class="search-input"
              formControlName="searchName"
              type="text"
              id="searchName"
              (keyup)="handleKeyUp($event)"
            />
            <button class="clrsgn-btn-default float-end search-button" type="button" (click)="setSearchText()">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </mat-form-field>
        </span>
        <span class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label class="clrsgn-label">Filter By Role</mat-label>
            <mat-select formControlName="roleFilter" data-cy="roleDropdown">
              <mat-option
                *ngFor="let role of filterAccountRoles"
                [value]="role.value"
                (click)="setSearchRole()"
              >
                {{ role.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
    </form>
    <div *ngIf="clientAccounts.length === 0 && this.isLoading === false" class="section-box">
      <h4>Looks like that client doesn't have any users that you can edit yet!</h4>
      <p>Click the 'Add User' button to start adding new signing agents, client admins, order managers etc.</p>
        <button type="button"
                class="clrsgn-btn-default"
                (click)="openAddUserModal()">
          + Add User
        </button>
    </div>
    <div *ngIf="clientAccounts.length > 0">
      <div>
        <h2 class="float-start">Users</h2>
        <button type="button"
                class="clrsgn-btn-default float-end"
                (click)="openAddUserModal()">
          + Add User
        </button>
      </div>
      <table id="clientAccountSectionTable" class="table table-striped table-responsive" aria-describedby="Client accounts for selected client">
        <thead>
          <tr>
            <th scope="col" class="text-start sortable" class="name-table-header" [ngClass]="{'active-sort-column': sortColumn == 'Name'}" style="width: 40vw" (click)="changeSort('name')" (keyup)="handleKeyUp($event, 'name')">
              Name
              <span class="fas fa-arrow-down" *ngIf="!sortAscending && sortColumn === 'name'"></span>
              <span class="fas fa-arrow-up" *ngIf="sortAscending && sortColumn === 'name'"></span>
            </th>
            <th scope="col" class="text-start sortable" class="name-table-header" [ngClass]="{'active-sort-column': sortColumn == 'EmailAddress'}" style="width: 40vw" (click)="changeSort('email')" (keyup)="handleKeyUp($event, 'email')">
              Email
              <span class="fas fa-arrow-down" *ngIf="!sortAscending && sortColumn === 'email'"></span>
              <span class="fas fa-arrow-up" *ngIf="sortAscending && sortColumn === 'email'"></span>
            </th>
            <th scope="col" class="text-start" [ngClass]="{'active-sort-column': sortColumn == 'ClientLoanNumber'}" style="width: 50vw">
              Roles
              <span class="fa-regular fa-circle-question tooltip-icon" ngbTooltip="Add and remove roles that apply to the user" placement="bottom" openDelay="500"></span>
            </th>
            <th scope="col" class="text-start" [ngClass]="{'active-sort-column': sortColumn == 'Actions'}" style="width: 10vw">
              Actions
            </th>
          </tr>
        </thead>
        <p *ngIf="clientAccountsDisplay.length === 0 && this.isLoading === false" class="mt-1">No results for the given search criteria / All users have been removed from the current page</p>
        <tbody *ngIf="clientAccountsDisplay.length > 0">
          <tr *ngFor="let clientAccount of clientAccountsDisplay">
            <td>
              {{ clientAccount.name }}
            </td>
            <td>
              {{ clientAccount.email }}
            </td>
            <td>
              <mat-form-field class="clrsgn-form-field">
                <mat-select placeholder="Roles" [(value)]="clientAccount.roles" multiple>
                  <mat-option *ngFor="let role of accountRoles" #matOption [value]="role.value" (click)="editClientAccount(matOption.selected, clientAccount, role.value)">{{ role.displayValue }}</mat-option>
                </mat-select>
              </mat-form-field>            
            </td>
            <td>
              <button
                type="button"
                class="clrsgn-btn-outline-default action-btn pt-2 reset-btn"
                (click)="onDelete(clientAccount)"
              >
                <i class="fas fa-trash-alt action-icon cursor-hand"></i>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      <div class="row pagination-section">
        <div class="col align-self-end">
          <app-pagination-form
            [numPages]="pagination.totalPages"
            [currentPage]="pagination.pageNumber"
            [currentItemsPerPage]="pagination.pageSize"
            [canGoForward]="pagination.canMoveForward"
            [canGoBackward]="pagination.canMoveBackward"
            (change)="onPaginatorChanged()">
          </app-pagination-form>
        </div>
      </div>
  </div>
</ng-container>
