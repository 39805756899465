import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatButtonToggleChange, MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { of, Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { CapabilitiesService } from "src/app/shared/services/capabilities/capabilities.service";
import {
  DigiDocsSection,
  DigiDocsStore,
} from "src/app/digitization-tool/+state/digi-docs.store";
import { OrdersService } from "src/app/shared/services/orders.service";

import { DocumentUploadModalComponent } from "../document-upload-modal/document-upload-modal.component";

@UntilDestroy()
@Component({
  selector: "app-sub-header",
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatSlideToggleModule],
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.scss"],
})
export class SubHeaderComponent implements OnInit {
  @Output() handleGetAllDigitizableDocuments = new EventEmitter();

  public canViewVoidedDocuments: boolean;
  public digiDocsSection: any = "";
  public orderId: number;
  public showVoidedDocuments$: Observable<boolean> = of(false);
  isOrderLocked: boolean
  orderDoesntHaveDocs: boolean;
  productType: string;

  constructor(
    private readonly route: ActivatedRoute,
    private capabilitiesService: CapabilitiesService,
    private readonly componentStore: DigiDocsStore,
    private readonly modalService: NgbModal,
    private readonly ordersService: OrdersService
  ) {
    this.showVoidedDocuments$ = this.componentStore.showVoidedDocuments$;

    this.componentStore.sectionToDisplay$
      .pipe(
        tap((digiDocsSection: DigiDocsSection) => {
          this.digiDocsSection = digiDocsSection;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnInit() {
    this.orderId = this.route.snapshot.params.orderId;
    this.ordersService.getOrder(this.orderId).pipe(
      tap(order => {
        this.productType = order.productType;
        this.isOrderLocked = order.statusCode !== "READY";
      }),
    );
    this.capabilitiesService.getCapabilitiesByOrderId(this.orderId);
    this.capabilitiesService.userHasCapability("order", "CanShowVoidedDocuments")
      .subscribe(result => this.canViewVoidedDocuments = result);
    this.componentStore.myDocuments$
      .subscribe(docList => {
        this.orderDoesntHaveDocs = docList.length === 0 ? true : false;
      }
    );   
  }

  handleDigiDocsSectionChange(event: MatButtonToggleChange) {
    this.digiDocsSection = event.value;

    if (event.value === DigiDocsSection.THUMBNAILS) {
      this.componentStore.sectionToDisplay(event.value);
    }

    if (event.value === DigiDocsSection.LIST) {
      this.componentStore.sectionToDisplay(event.value);
    }
  }

  handleSlideToggleChange(event: MatSlideToggleChange) {
    this.componentStore.setShowVoidedDocuments(event.checked);
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.openUploadDocumentModal();
    }
  }

  openUploadDocumentModal(): void {
    const modal = this.modalService.open(DocumentUploadModalComponent, {
      windowClass: "document-upload-modal",
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.orderId = this.orderId;
    modal.componentInstance.closingType = this.productType;

    modal.result.then((result) => {
      if (result !== "cancel") {
        this.handleGetAllDigitizableDocuments.emit();
      }
    });
  }
}
