<header>
  <ng-container *ngIf="!environmentIsProduction">
    <div class="alert-bar">
      <fa-icon [icon]="faExclamationTriangle" class="mx-2 white"></fa-icon>
      <span class="warning-message">This is a preview environment. This is not a live website</span>
      <fa-icon [icon]="faExclamationTriangle" class="mx-2 white"></fa-icon>
    </div>
  </ng-container>
  <nav class="navbar navbar-expand-sm nex-nav">
    <div class="container-fluid">
      <a [routerLink]="['/manage-orders']" class="navbar-brand">
        <img
          class="ml-lg-6"
          src="assets/images/clearsignlogo_large.png"
          alt="Clear Sign Logo"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="nav navbar-nav navbar-right">
        <div
        [showIfHasCapability]="[
          {
            capabilityString: 'CanAccessSupportWing',
            type: 'system'
          }
        ]"
        >
          <li class="nav-item">
            <button 
              class="btn btn-secondary nexsys-nav-item"
              [routerLink]="['/support-manage-users']"
            >
              Support
            </button>
          </li>
        </div>
        <div
        [showIfHasCapability]="[
          {
            capabilityString: 'CanAccessAdminWing',
            type: 'client'
          }
        ]"
        >
          <li class="nav-item">
            <button 
              class="btn btn-secondary nexsys-nav-item"
              [routerLink]="['/manage-users']"
            >
              Admin
            </button>
          </li>
        </div>
        <li class="nav-item" *ngIf="this.isUserLoggedIn()">
          <button
            class="btn btn-secondary nexsys-nav-item"
            [routerLink]="['/manage-orders']"
          >
            Manage Orders
          </button>
        </li>
        <li class="nav-item">
          <div
            class="d-inline-block"
            ngbDropdown
            placement="bottom-right"
            *ngIf="this.isUserLoggedIn()"
          >
            <button
              class="btn btn-secondary nexsys-nav-item dropdown-toggle"
              id="headerUserDropdown"
              ngbDropdownToggle
            >
              {{ userName }}
            </button>
            <div ngbDropdownMenu aria-labelledby="headerUserDropdown">
              <button ngbDropdownItem (click)="goToMyAccount()">
                My Account
              </button>
              <button ngbDropdownItem (click)="logout()">
                Logout
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
