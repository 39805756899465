<div class="modal-body">
  <form [formGroup]="multifactorCodeForm" class="clrsgn-form">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="clrsgn-form-field">
          <mat-label>Verification Method</mat-label>
          <input
            [matAutocomplete]="autocompleteVerificationType"
            aria-label="Verification Method"
            formControlName="verificationType"
            matInput
            type="text"
          />
          <mat-autocomplete
            #autocompleteVerificationType="matAutocomplete"
          >
            <mat-option
              (click)='onDropdownChange($event)'
              [value]="verificationType"
              *ngFor="let verificationType of verificationTypes"
            >
              {{ verificationType }}
            </mat-option>
          </mat-autocomplete>
          <app-form-error
            [controlName]="'verificationType'"
            [group]="multifactorCodeForm"
          ></app-form-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row marginTopTenPx">
      <div class="col">
        <mat-form-field appearance="fill" class="clrsgn-form-field">
          <mat-label for="phoneNumber" class="clrsgn-label">
            Phone Number
          </mat-label>
          <input
            matInput
            maxlength="128"
            type="tel"
            formControlName="phoneNumber"
            id="phoneNumber"
            prefix="+1"
            mask="(000) 000-0000"
            pattern="\d*"
            inputmode="numeric"
          />
          <app-form-error
            [group]="multifactorCodeForm"
            [controlName]="'phoneNumber'"
          ></app-form-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    id="cancelButton"
    type="button"
    class="amr-btn-outline-warning"
    data-bs-dismiss="modal"
    (click)="resetModal('cancel')"
  >
    Cancel
  </button>
  <button
    id="saveParticipant"
    type="button"
    class="amr-btn-default"
    data-bs-dismiss="modal"
    (click)="patchVerification()"
  >
    Save Participant
  </button>
</div>
