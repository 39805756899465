<div
  *ngFor="let pdfThumbnail of pdfThumbnails$ | async"
  class="document-thumbnail-container"
  [ngClass]="{
    hide: pdfThumbnail?.isVoid && (showVoidedDocuments$ | async) === false
  }"
>
  <figure>
    <div class="document-thumbnail-loading blink">
      <span class="fas fa-spinner fa-spin nexsys-green-accent fa-2x ms-2"></span>
    </div>
    <div class="document-thumbnail-image-parent-container">
      <div class="document-thumbnail-image-child-container">
        <div class="document-thumbnail-button-container">
          <document-editor-list-dropdown-button (editByDocumentId)="handleEditByDocumentId($event)"
                                                (deleteByDocumentId)="handleDeleteByDocumentId($event)"
                                                (downloadByDocumentId)="handleDownloadByDocumentId($event, pdfThumbnail?.figcaption)"
                                                [actions]="getAvailableActions(pdfThumbnail)"
                                                [docId]="pdfThumbnail?.docId"></document-editor-list-dropdown-button>
        </div>
        <img (load)="handleThumbnailLoad($event)"
             alt="{{ pdfThumbnail?.alt }}"
             class="hide"
             height="{{ pdfThumbnail?.image?.height }}"
             src="{{ pdfThumbnail?.image?.src ?? pdfThumbnail?.image?.dataUrl }}"
             width="{{ pdfThumbnail?.image?.width }}" />
      </div>
    </div>    
    <figcaption>
      <ng-container [ngSwitch]="pdfThumbnail?.docStatus">
        <span *ngSwitchCase="documentStatuses.NeedsAttention.toString()" class="document-status-needs-attention" ngbTooltip="{{ pdfThumbnail?.errorText }}" placement="bottom">
          <i class="fa-solid fa-triangle-exclamation"></i>
        </span>
        <span *ngSwitchCase="documentStatuses.Voided.toString()" class="document-status-voided">
          <i class="fa-regular fa-circle-xmark"></i>
        </span>
        <span *ngSwitchCase="documentStatuses.Ready.toString()" class="document-status-ready">
          <i class="fa-regular fa-circle-check"></i>
        </span>
        <span *ngSwitchDefault class="document-status-ready">
        </span>
      </ng-container>
      {{ pdfThumbnail?.figcaption }}
    </figcaption>
  </figure>
</div>
