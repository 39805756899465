<div class="page-header-bar">
  <div class="content85percent">
    <div class="float-start">
      <h1>Clear Sign Portal End User License Agreement</h1>
    </div>
  </div>
</div>
<div class="nex-eula">
  <div class="row margin-top-thirty-px" [innerHTML]="eulaFileContent"></div>
</div>
<div *ngIf="hasContent && !hasAcceptedEula" class="row margin-top-thirty-px">
  <div align="center" style="width: 100%;">
    <button type="button" class="clrsgn-btn-default" (click)="onAccept()" style="margin-right: 40px;">I ACCEPT</button>
    <button type="button" class="clrsgn-btn-outline-warning" (click)="onDecline()">I DECLINE</button>
  </div>
</div>
