<ng-container>
  <div class="modal-header">
    <h4 *ngIf="isEdit"
        class="modal-title"
        style="margin: 0 auto"
        id="createOrderModalLabel">
      Edit Order
    </h4>
    <h4 *ngIf="!isEdit"
        class="modal-title"
        style="margin: 0 auto"
        id="createOrderModalLabel">
      Create Order
    </h4>
  </div>
  <div class="modal-body" data-cy="createOrderModalBody">
    <ng-container *ngIf="(clients$ | async) && (clients$ | async)?.length > 0; else loading">
      <form [formGroup]="orderForm" class="clrsgn-form">
        <p>*Required Information</p>
        <span class="modal-grp-heading">Order Info</span>
        <div class="row marginTopTenPx">
          <div class="col-6">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label class="clrsgn-label">Organization</mat-label>
              <mat-select data-cy="organizationDropdownList"
                          placeholder="--Select An Organization--"
                          [selectOnTab]="true"
                          id="clientId"
                          formControlName="clientId">
                <input matInput placeholder="Filter" class="clrsgn-form-field mat-option" formControlName="clientFilter1" (input)="performClientFilter()" #clientInput>
                <hr />
                <mat-option *ngFor="let client of filteredClients"
                            [value]="client.clientId">
                  {{ client.clientName }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="orderForm"
                              [controlName]="'clientId'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="scheduledDate" class="clrsgn-label">
                Scheduled Date
              </mat-label>
              <input matInput
                     [matDatepicker]="scheduledDatepicker"
                     [min]="minDate"
                     formControlName="scheduledDate"
                     id="scheduledDate"
                     [formGroup]="orderForm" />
              <mat-datepicker-toggle matSuffix [for]="scheduledDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #scheduledDatepicker></mat-datepicker>
              <app-form-error [group]="orderForm"
                              [controlName]="'scheduledDate'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row marginTopTwentyPx">
          <div class="col-6">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="closingType" class="clrsgn-label">Signing Type</mat-label>
              <mat-select data-cy="signingTypeDropdownList"
                          placeholder="--Select A Signing Type--"
                          [selectOnTab]="true"
                          id="closingType"
                          formControlName="closingType">
                <mat-option *ngFor="let closingType of closingTypes"
                            [value]="closingType.key">
                  {{ closingType.value }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="orderForm"
                              [controlName]="'closingType'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="scheduledTime" class="clrsgn-label">Scheduled Time</mat-label>
              <input matInput
                     id="scheduledTime"
                     readonly
                     formControlName="scheduledTime"
                     [formGroup]="orderForm"
                     [ngxMatTimepicker]="scheduledTimeTimepicker" />
              <mat-icon matSuffix
                        class="timepicker-mat-icon"
                        (click)="scheduledTimeTimepicker.open()"><img src="../../assets/images/timepicker_icon.png" /> </mat-icon>
              <app-form-error [group]="orderForm"
                              [controlName]="'scheduledTime'"></app-form-error>
            </mat-form-field>
            <ngx-mat-timepicker (closed)="handleTimePickerClosed($event)"
                                (opened)="handleTimePickerOpened($event)"
                                #scheduledTimeTimepicker
                                enableKeyboardInput="true">
            </ngx-mat-timepicker>
          </div>
        </div>
        <div *ngIf="(showWarning$ | async)"
             class="row known-signer-warning padding-fifteen margin-top-twenty-px">
          <div class="icon-container padding-fifteen">
            <span class="fa fa-exclamation-triangle fa-2x" aria-hidden="true"></span>
          </div>
          <div class="warning-text">Use of this Signing Type will remove the verification process for all Signers during check-in. Use only if the Notary has personal knowledge of all Signers.</div>
        </div>
        <span class="modal-grp-heading marginTopTwentyFivePx">
          Transaction Information
        </span>
        <div class="row marginTopTwentyPx">
          <div class="col-12 col-md-12 col-lg-6 mb-md-2">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="loanNumber" class="clrsgn-label">Loan #</mat-label>
              <input matInput
                     type="text"
                     maxlength="32"
                     [ngClass]="{'invalid-text-field': isFieldInvalidDirty(orderForm, 'loanNumber')}"
                     id="loanNumber"
                     formControlName="loanNumber">
              <app-form-error [group]="orderForm"
                              [controlName]="'loanNumber'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-12 col-lg-6 ">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="thidPartyOrderIdentifier" class="clrsgn-label">
                3rd Party Order ID
              </mat-label>
              <input matInput
                     type="text"
                     maxlength="32"
                     [ngClass]="{'invalid-text-field': isFieldInvalidDirty(orderForm, 'thirdPartyOrderIdentifier')}"
                     id="thirdPartyOrderIdentifier"
                     formControlName="thirdPartyOrderIdentifier">
              <app-form-error [group]="orderForm"
                              [controlName]="'thirdPartyOrderIdentifier'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row marginTopTenPx">
          <div class="col-12 col-md-12 col-lg-6">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="partnerOrderIdentifier" class="clrsgn-label">
                My Order Id
              </mat-label>
              <input matInput
                     type="text"
                     maxlength="32"
                     id="partnerOrderIdentifier"
                     formControlName="partnerOrderIdentifier">
              <app-form-error [group]="orderForm"
                              [controlName]="'partnerOrderIdentifier'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
        <span class="modal-grp-heading marginTopTwentyFivePx">
          Property Information
        </span>
        <div class="row marginTopTenPx">
          <div class="col">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="address" class="clrsgn-label"> Street Address </mat-label>
              <input matInput
                     type="text"
                     maxlength="100"
                     id="address"
                     formControlName="address">
              <app-form-error [group]="orderForm"
                              [controlName]="'address'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="clrsgn-form-field" appearance="fill">
              <mat-label for="address2" class="clrsgn-label">
                Street Address 2
              </mat-label>
              <input matInput
                     type="text"
                     maxlength="50"
                     id="address2"
                     formControlName="address2">
              <app-form-error [group]="orderForm"
                              [controlName]="'address2'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row marginTopTenPx">
          <div class="col-12 col-md-4 col-lg-6">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="city" class="clrsgn-label"> City </mat-label>
              <input matInput
                     type="text"
                     maxlength="50"
                     id="city"
                     formControlName="city">
              <app-form-error [group]="orderForm"
                              [controlName]="'city'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-5 col-lg-3">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="state" class="clrsgn-label">State</mat-label>
              <mat-select data-cy="stateDropdownList"
                          placeholder="--Select A State--"
                          [selectOnTab]="true"
                          id="state"
                          formControlName="state">
                <mat-option data-cy="stateOptions"
                            *ngFor="let state of states"
                            [value]="state.code">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <app-form-error [group]="orderForm"
                              [controlName]="'state'"></app-form-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-3 col-lg-3">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="zip" class="clrsgn-label"> Zip </mat-label>
              <input matInput
                     onlyNumbers
                     type="text"
                     maxlength="5"
                     id="zip"
                     formControlName="zip">
              <app-form-error [group]="orderForm"
                              [controlName]="'zip'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="clrsgn-btn-outline-primary"
            (click)="activeModal.close('cancel')"
            data-cy="formCancelOrderButton">
      Cancel
    </button>
    <button *ngIf="!isEdit"
            type="button"
            class="clrsgn-btn-default"
            (click)="save()"
            data-cy="formCreateOrderButton">
      Create Order
    </button>
    <button *ngIf="isEdit"
            type="button"
            class="clrsgn-btn-default"
            [disabled]="isEdit && (clients$ | async)?.length == 0"
            (click)="updateOrder()"
            data-cy="formUpdateOrderButton">
      Update Order
    </button>
  </div>
  <ng-template #loading>
    <div class="jumbotron empty-jumbotron-message">
      <div class="d-flex justify-content-center align-items-center">
        <span class="jumbotron-heading"></span>
        <span class="fas fa-spinner fa-spin clrsgn-accent fa-2x ms-2"></span>
      </div>
    </div>
  </ng-template>
</ng-container>
