<ng-container>
  <ng-container *ngIf="order$ | async as order">
    <app-order-detail-header [order]="order"
                             [isOrderLocked]="isOrderLocked$ | async"
                             [isCompleteCancelledOrOptout]="isCompleteCancelledOrOptout$ | async"
                             [canCompleteOrder]="canCompleteOrder"
                             (cancelled)="onCancelOrder()">
    </app-order-detail-header>
    <div *ngIf="isOrderLocked$ | async"
         class="order-detail-info-banner success-banner"
         [ngClass]="{
        'success-banner': order?.statusCode === 'COMPLETE',
        'warning-banner': order?.statusCode !== 'COMPLETE'
      }">
      <div class="banner-content">
        <span class="fas fa-lg"
              [ngClass]="{
            'fa-check-circle': order?.statusCode === 'COMPLETE',
            'fa-exclamation-triangle': order?.statusCode !== 'COMPLETE'
          }">
        </span>
        Editing not allowed: Order status is currently {{ orderStatus$ | async | titleCaseStatusCode }}
      </div>
    </div>
    <div class="content85percent">
      <app-loan-information [toggleLoanInformationFormEdit]="toggleLoanInformationFormEdit"
                            [isOrderPreSign]="orderIsPresign$ | async"></app-loan-information>
      <div class="text-center marginTopFiftyPx">
        <div class="lg-action-btn"
             [showIfHasCapability]="[
            {
              capabilityString: 'CanViewAllDocuments',
              type: 'order',
              id: order?.id
            }
          ]">
          <button type="button"
                  (click)="
              displayPdf(downloadDocumentsUrl, 'ClosingDocuments', 'COMPLETE')
            "
                  class="btn btn-white loan-action-btn width-widget-default"
                  [disabled]="downloadDocumentsUrl?.length <= 0 || !isOrderReadyOrInprogress"
            data-cy="viewDocumentsButton"
          >
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/viewDocuments.svg" />
            </div>
            <div class="loan-action-btn-text">
              <span>View Documents</span>
            </div>
          </button>
        </div>
        <div class="lg-action-btn"
             [hidden]="!isHybrid"
             [showIfHasCapability]="[
            {
              capabilityString: 'CanPrintDocuments',
              type: 'order',
              id: order?.id
            }
          ]">
          <button type="button"
                  (click)="displayPdf(printDocumentsUrl, 'ClosingDocuments')"
                  class="btn btn-white loan-action-btn width-widget-default"
                  [disabled]="isOrderComplete || printDocumentsUrl?.length === 0"
                  data-cy="printDocumentsButton">
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/printDocuments.svg" />
            </div>
            <div class="loan-action-btn-text">
              <span>Print Documents</span>
            </div>
          </button>
        </div>
        <div class="lg-action-btn"
             *ngIf="joinSigningUrl !== null; else loadingButton">
          <button type="button"
                  (click)="openSigningSession(joinSigningUrl)"
                  class="btn btn-white loan-action-btn width-widget-default"
                  [disabled]="!canJoinSigning">
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/joinSigning.svg" />
            </div>
            <div class="loan-action-btn-text">
              <span>Join Signing</span>
            </div>
          </button>
        </div>
        <div class="lg-action-btn"
             [showIfHasCapability]="[
            {
              capabilityString: 'CanDownloadExecutedPackage',
              type: 'order',
              id: order?.id
            }
          ]">
          <button type="button"
                  (click)="
              displayPdf(downloadExecutedPackageUrl, 'ExecutedClosingPackage')
            "
                  class="btn btn-white loan-action-btn width-widget-default"
                  [disabled]="!this.isOrderComplete || downloadExecutedPackageUrl?.length <= 0"
            data-cy="downloadExecutedClosingPackageButton"
          >
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/downloadDocuments.svg" />
            </div>
            <div class="loan-action-btn-text">
              <span>Download Documents</span>
            </div>
          </button>
        </div>
        <div class="lg-action-btn"
             [hidden]="isHybrid"
             [showIfHasCapability]="[
            {
              capabilityString: 'CanDownloadNotarizationInformation',
              type: 'order',
              id: order?.id
            }
          ]">
          <button type="button"
                  (click)="displayPdf(downloadNotarizationInformationPdfUrl, 'NotarizationInformation')"
                  class="btn btn-white loan-action-btn width-widget-default"
                  [disabled]="downloadNotarizationInformationPdfUrl?.length <= 0">
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/downloadNotaryInfo.svg" />
            </div>
            <div class="loan-action-btn-text">
              <span>Download Notarization Info</span>
            </div>
          </button>
        </div>
        <div class="lg-action-btn"
             *ngIf="order != null && (isRON || isKnownSignerRON) && isVideoDownloadAvailable">
          <button *ngIf="isOrderComplete"
                  type="button"
                  (click)="onDownloadClosingVideoClick()"
                  class="btn btn-white loan-action-btn width-widget-default"
                  [ngClass]="{'video-is-downloading': isVideoDownloading}"
                  [showIfHasCapability]="[
              {
                capabilityString: 'CanDownloadSigningVideo',
                type: 'order',
                id: order?.id
              }
            ]"
                  data-cy="downloadVideoButton">
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/downloadVideo.svg" />
            </div>
            <div class="loan-action-btn-text">
              <span *ngIf="!isVideoDownloading; else videoIsDownloading">
                Download Video
              </span>
              <ng-template #videoIsDownloading>
                <span>Downloading&nbsp;</span>
                <span class="fas fa-spin fa-circle-notch"></span>
              </ng-template>
            </div>
          </button>
          <button *ngIf="isOrderOptedOut || isOrderCancelled"
                  type="button"
                  (click)="onDownloadClosingVideoClick()"
                  class="btn btn-white loan-action-btn width-widget-default"
                  [ngClass]="{'video-is-downloading': isVideoDownloading}"
                  [showIfHasCapability]="[
                    {
                      capabilityString: 'CanDownloadCancelledAndOptedOutOrderVideos',
                      type: 'system'
                    }
                  ]"
                  data-cy="downloadVideoButton">
            <div class="loan-action-btn-icon-default">
              <img src="assets/images/downloadVideo.svg" alt="download video image"/>
            </div>
            <div class="loan-action-btn-text">
              <span *ngIf="!isVideoDownloading; else videoIsDownloading">
                Download Video
              </span>
              <ng-template #videoIsDownloading>
                <span>Downloading&nbsp;</span>
                <span class="fas fa-spin fa-circle-notch"></span>
              </ng-template>
            </div>
          </button>
        </div>
      </div>
      <div class="marginTopSixtyPx" data-cy="participantsSection">
        <div class="d-flex flex-row">
          <span class="section-header document-text-header">
            Participants
          </span>
          &nbsp;
          <button *ngIf="!(isOrderLocked$ | async)"
                  class="clrsgn-btn-default m-3 ms-auto"
                  data-backdrop="static"
                  data-keyboard="false"
                  (click)="openParticipantModal()"
                  [showIfHasCapability]="[
              {
                capabilityString: 'CanAddParticipant',
                type: 'order',
                id: order?.id
              }
            ]">
            Add Participant
          </button>
        </div>
        <ng-container *ngIf="participants$ | async as participants; else loadingParticipants">
          <app-participant-list #participantTable
                                *ngIf="participants?.length > 0; else noParticipants"
                                [participants]="participants"
                                [showSessionLink]="isOrderReadyOrInprogress"
                                [showActions]="isOrderReadyOrInprogress"
                                [participantPresignStatuses]="participantPresignStatuses"
                                [orderId]="order?.id"
                                (geturlCalled)="getClosingUrlAction($event)"
                                (editActionCalled)="editParticipantAction($event)"
                                (removeActionCalled)="removeParticipantAction($event)"
                                (resetLoginActionCalled)="openResetLoginModal($event)">
          </app-participant-list>
        </ng-container>
      </div>
      <div class="marginTopSixtyPx"
           data-cy="organizersSection"
           *ngIf="
          (isOrderOrganizersEnabled$ | async) &&
          (this.capabilitiesService.userHasCapability(
            'order',
            'CanViewOrganizers',
            order?.id
          ) | async)
        ">
        <div class="d-flex flex-row">
          <span class="section-header document-text-header">
            Order Organizers
          </span>
          <ng-template #tooltipContent>
            <span><strong>Order Organizers</strong> gives you the ability to assign additional users from outside of your immediate organization to manage this order.</span>
          </ng-template>
          <button (click)="toggleShowOrderOrganizers()"
                  type="button"
                  class="ms-2"
                  [ngClass]="(isOrderOrganizersShown)?'fas fa-chevron-circle-up show-hide-icon' :
                        'fas fa-chevron-circle-down show-hide-icon'">
          </button>
          <button *ngIf="!(isOrderLocked$ | async)"
                  class="clrsgn-btn-default m-3 ms-auto"
                  data-backdrop="static"
                  data-keyboard="false"
                  (click)="openAddOrderOrganizer()"
                  [showIfHasCapability]="[
              {
                capabilityString: 'CanAddOrganizer',
                type: 'order',
                id: order?.id
              }
            ]">
            Add Order Organizer
          </button>
        </div>
        <app-order-organizer-list #organizerTable
                                  [showActions]="isOrderReady"
                                  [orderId]="order?.id"
                                  *ngIf="isOrderOrganizersShown">
        </app-order-organizer-list>
      </div>
      <div class="marginTopSeventyFivePx" data-cy="documentsSection">
        <div class="d-flex flex-row">
          <span class="section-header document-text-header mb-4">Documents</span>
        </div>
        <div *ngIf="(this.ruleViolations?.documentRules.length > 0)"
             class="document-rule-section">
          <img src="assets/images/fa-exclamation-circle-o.png" class="ms-2 float-start exclamation-img" alt="Exclamation point" />
          <span class="ms-3">
            Document(s) need attention. Open the
            <span class="document-rule-text-link" (click)="openDigiDocs()">document editor</span>
            and fix any errors to continue.
          </span>
        </div>
        <div>
          <button *ngIf="!(isOrderLocked$ | async)"
                  type="button"
                  (click)="openDigiDocs()"
                  class="btn btn-white loan-action-btn width-widget-docs doc-float-left mb-5 me-4"
                  data-cy="documentEditorButton"
                  [showIfHasCapability]="[
              {
                capabilityString: 'CanDigitizeDocuments',
                type: 'order',
                id: order?.id
              }
            ]">
            <div class="loan-action-btn-icon-docs doc-float-start m-3">
              <img class="float-start me-3 mb-3" src="assets/images/documentEditor.svg" alt="Document edtior" />
            </div>
            <div>
              <h5 class="text-start mt-4">Document Editor</h5>
              <div class="text-start">
                Open the editor to prepare documents for signing:
                <ul class="text-start doc-text-list">
                  <li>Add / remove documents</li>
                  <li>Add signatures and endorsements to your existing documents</li>
                  <li>Edit document details</li>
                </ul>
              </div>
            </div>
            <div class="clrsgn-btn-default docs-widget-btn doc-float-left">
              <span>Add & Prepare Documents for Signing</span>
            </div>
          </button>
          <ng-container>
            <ng-container *ngIf="documents$ | async as documents; else loadingDocuments">
              <app-documents-list *ngIf="documents?.length > 0; else noDocuments"
                                  [order]="order"
                                  [documents]="documents$ | async"
                                  [isOrderLocked]="isOrderLocked$ | async"
                                  [ruleViolations]="ruleViolations"
                                  (loadDocumentsCalled)="loadDocuments()"></app-documents-list>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-template #loadingButton>
      <div class="lg-action-btn">
        <button type="button" disabled class="btn btn-white loan-action-btn width-widget-default">
          <div class="loan-action-btn-icon-default">
            <span class="fas fa-spinner fa-spin"></span>
          </div>
          <div class="loan-action-btn-text">
            <span>Join Signing</span>
          </div>
        </button>
      </div>
    </ng-template>

    <ng-template #browserNotSupportedModal>
      <div class="modal-header d-flex align-items-center justify-content-between">
        <h5 class="modal-title" id="saveTemplateModalLabel">
          Browser not supported
        </h5>
        <span class="fas fa-times text-white" (click)="closeModal()"></span>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center flex-column">
        <p>Please use Google Chrome to complete your Remote Online Notarization.</p>
        <p>If you need further assistance, please call Nexsys Support at: (844) 913-1386.</p>
      </div>
    </ng-template>

    <ng-template #loadingParticipants>
      <div class="jumbotron empty-jumbotron-message">
        <div class="d-flex justify-content-center align-items-center">
          <span class="jumbotron-heading">
            Loading Participants
          </span>
          <span class="fas fa-spinner fa-spin nexsys-green-accent fa-2x ms-2"></span>
        </div>
        <app-skeleton-loading-list [rowCount]="2"
                                   [tableName]="'participants'"></app-skeleton-loading-list>
      </div>
    </ng-template>

    <ng-template #noParticipants>
      <div class="jumbotron empty-jumbotron-message"
           data-cy="emptyParticipantsMessage">
        <span class="jumbotron-heading">
          No participants to display.
        </span>
        <br />
        <span *ngIf="!(isOrderLocked$ | async)"
              class="jumbotron-text"
              [showIfHasCapability]="[
            {
              capabilityString: 'CanAddParticipant',
              type: 'order',
              id: order?.id
            }
          ]">
          Start adding your borrowers, sellers, and signing agent to this order by clicking
          <a class="amr-link fw-bold"
             (click)="openParticipantModal()">add participant</a>
          above
        </span>
      </div>
    </ng-template>

    <ng-template #loadingDocuments>
      <div class="jumbotron empty-jumbotron-message float-end docs-section-width docs-section-height">
        <div class="d-flex justify-content-center align-items-center">
          <span class="jumbotron-heading">Loading Documents</span>
          <span class="fas fa-spinner fa-spin nexsys-green-accent fa-2x ms-2"></span>
        </div>
        <app-skeleton-loading-list [rowCount]="2"
                                   [tableName]="'documents'"></app-skeleton-loading-list>
      </div>
    </ng-template>

    <ng-template #noDocuments>
      <div class="no-doc-design docs-section-width p-4 mb-4"
           [ngClass]="{'float-end': !(isOrderLocked$ | async)}"
           data-cy="emptyDocumentsMessage">
        <p class="fw-bold">No documents added.</p>
        <span *ngIf="!(isOrderLocked$ | async)"
              [showIfHasCapability]="[
            {
              capabilityString: 'CanAddDocument',
              type: 'order',
              id: order?.id
            }
          ]">
          Click
          "<a class="amr-link fw-bold add-doc-text" (click)="openDigiDocs()">
            Add & Prepare Documents for Signing
          </a>"
          to open the Document Editor and start adding documents for the signing session.
        </span>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
