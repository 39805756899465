<div *ngIf="activeTab === 'client'">
  <form [formGroup]="searchForm">
    <mat-form-field appearance="fill" class="clrsgn-form-field search-mat">
      <mat-label class="clrsgn-label">Email Address</mat-label>
      <input
             matInput
             type="text"
             class="search-input"
             id="emailAddress"
             formControlName="searchEmail"
             (keyup.enter)="search()"
             />
      <button class="clrsgn-btn-default float-end search-button" type="button" (click)="search()">
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
    </mat-form-field>
    <button type="button"
            class="clrsgn-btn-default float-end"
            (click)="openAddUserModal()">
      + Add User
    </button>
    <p *ngIf="!isSearchResults && !searchPerformed">Search for a user by using the search by email address functionality above. Or add a new user to a client by using the 'Add User' button to the right.</p>
    <p *ngIf="!isSearchResults && searchPerformed">No results to display.</p>
    <div *ngIf="isSearchResults">
      <h5 class="float-start">Name: {{ this.fullName }}<br>Email: {{ this.emailAddress }}</h5>
      <button class="clrsgn-btn-outline-default float-start ms-3"
            (click)="openAddAccountSignatureModal()"
            >
        Upload Signature
      </button>
    </div>
    <p class="clear-both" *ngIf="isSearchResults && displayClientAccounts && displayClientAccounts.length === 0">User has no client accounts. Please add them to a client using the button to the right.</p>
    <div *ngIf="displayClientAccounts && displayClientAccounts.length > 0">
      <button type="button"
              class="clrsgn-btn-outline-default float-end"
              (click)="onDeleteAll()">
        Delete All Client Accounts
      </button>
      <table id="systemClientAccountsSectionTable" class="table table-striped table-responsive" aria-describedby="Client Accounts">
        <thead>
          <tr>
            <th scope="col" class="name-table-header"  style="width: 40vw">
              Client Name
            </th>
            <th scope="col" class="name-table-header"  style="width: 50vw">
              Account Types
            </th>
            <th scope="col" class="name-table-header"  style="width: 10vw">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let clientAccount of displayClientAccounts">
            <td>{{ clientAccount.clientName }}</td>
            <td>
              <mat-form-field class="clrsgn-form-field">
                <mat-select placeholder="Roles" [(value)]="clientAccount.roles" multiple>
                  <mat-option *ngFor="let role of accountRoles" #matOption [value]="role.value" (click)="editClientAccount(matOption.selected, clientAccount, role.value)">{{ role.displayValue }}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <button
                      type="button"
                      class="clrsgn-btn-outline-default pt-2"
                      (click)="onDelete(clientAccount)"
                      >
                <i class="fas fa-trash-alt action-icon cursor-hand"></i>
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
