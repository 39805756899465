<ng-container>
  <div class="filter-section">
    <form [formGroup]="searchForm" class="clrsgn-form search-form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="loanNumber" class="clrsgn-label">Loan #</mat-label>
            <input
              matInput
              type="text"
              formControlName="loanNumber"
              id="filterLoanNumber"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="clientLastName" class="clrsgn-label">Client Last Name</mat-label>
            <input
              matInput
              type="text"
              formControlName="clientLastName"
              id="filterClientLastName"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label class="clrsgn-label">Scheduled Date</mat-label>
              <mat-date-range-input [formGroup]="searchScheduledDate" [rangePicker]="picker">
                <input data-cy="scheduledDateStartDate" matStartDate formControlName="startDate">
                <input data-cy="scheduledDateEndDate" matEndDate formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <app-form-error [group]="searchScheduledDate" [controlName]="'startDate'"></app-form-error>
              <div *ngIf="!searchScheduledDate.get('startDate')?.hasError('required')">
                <app-form-error [group]="searchScheduledDate" [controlName]="'endDate'"></app-form-error>
              </div>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="address" class="clrsgn-label">Street Address</mat-label>
            <input
              matInput
              type="text"
              formControlName="address"
              id="filterAddress"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="thirdPartyOrderIdentifier" class="clrsgn-label">3rd Party Order ID</mat-label>
            <input
              matInput
              formControlName="thirdPartyOrderIdentifier"
              id="filterThirdPartyOrderIdentifier"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="clients" class="clrsgn-label">Organization</mat-label>
            <mat-select formControlName="client" (selectionChange)="onSetClient($event.value)" data-cy="organizationDropdown" #clientSelect>
              <input matInput placeholder="Filter" class="clrsgn-form-field mat-option ps-2" formControlName="clientFilter" (input)="performClientFilter()" #clientInput>
              <hr />
              <mat-option
                *ngFor="let client of filteredClients"
                [value]="client.clientId"
              >
                {{ client.clientName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isOrderManager && (isPortalAssigneeEnabled$ | async)" class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="assignees" class="clrsgn-label">Assigned To</mat-label>
            <mat-select (selectionChange)="onSetAssignee($event.value)" data-cy="assigneeDropdown">
              <input placeholder="Search Assignees" class="clrsgn-form-field" (keyup)="assigneeLoader($event.target.value)">
              <mat-option value="">
                No Search Filter
              </mat-option>
              <mat-option
                *ngFor="let assignee of (filteredAssignees$ | async)"
                [value]="assignee"
              >
                {{ assignee.fullName }} <span class="emailAddress">{{ assignee.emailAddress }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="orderStatus" class="clrsgn-label">Order Status</mat-label>
            <mat-select formControlName="orderStatus" (selectionChange)="onSetOrderStatus($event.value)" data-cy="orderStatusDropdown">
              <mat-option
                *ngFor="let orderStatus of orderStatuses"
                [value]="orderStatus.value"
              >
                {{ orderStatus.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col form-actions">
          <div class="row">
            <button type="button"
                    class="clrsgn-btn-default action-btn filter-btn"
                    (click)="onSearch()">
              Filter
            </button>
            &nbsp;
            <button
              type="button"
              class="clrsgn-btn-outline-default action-btn pt-2 reset-btn"
              (click)="onReset()"
            >
              Reset
            </button>
          </div>
          <div class="float-end">
            <input
              type="checkbox"
              id="ViewMyOrders"
              formControlName="viewMyOrders"
              name="ViewMyOrders"
              class="checkbox-formatting"
              (change)="onSearchViewSwap()"
            />
            <mat-label class="checkbox-label-formatting clrsgn-label"
                       for="ViewMyOrders">View My Orders</mat-label><span
                                                                      class="fa-regular fa-circle-question tooltip-icon ms-1"
                                                                      ngbTooltip="Shows orders you have created or have been assigned to as the Signing Agent"
                                                                      placement="bottom"
                                                                      container="body"
                                                                      openDelay="500"
                                                                    ></span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
