<ng-container>
  <div class="page-header-bar">
    <div class="content85percent">
      <div class="float-start">
        <h1>Order # <span *ngIf="order" data-cy="orderId">{{order.id}}</span></h1>
      </div>
      <div class="float-end">
        <div class="btn-group" role="group">
          <div *ngIf="!isCompleteCancelledOrOptout">
            <button class="btn fw-bold amr-link loan-information-header-btn"
                    (click)="cancelOrder()"
                    *ngIf="(capabilitiesService.userHasCapability('order', 'CanCancelOrder', order?.id) | async)">
              Cancel Order
            </button>
            <button class="clrsgn-btn-outline-default loan-information-header-btn"
                    (click)="completeOrder()"
                    *ngIf="(capabilitiesService.userHasCapability('order', 'CanCompleteOrder', order?.id) | async) && canCompleteOrder">
              Complete Order
            </button>
          </div>
          <button *ngIf="!toggleLoanInformationFormEdit && !isOrderLocked && (capabilitiesService.userHasCapability('order', 'CanEditOrder', order?.id) | async)"
                  type="button"
                  (click)="editLoanInformationForm()"
                  class="clrsgn-btn-default loan-information-header-btn">
            Edit Order
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
