<div *ngIf="orderDoesntHaveDocs; else orderHasDocs" class="mat-elevation-z8 m-4 p-4 document-null-state">
  <h4>No document added</h4>
  <span>
    Click 
    <span [allowLinkIfHasCapability]="[
      {
        capabilityString: 'CanAddDocument',
        type: 'document',
        id: doc?.id
      }
    ]"
    (click)="openUploadDocumentModal()"
    (keyup)="handleKeyUp($event)"
    class="amr-link fw-bold upload-documents"
    >
      Upload Documents
    </span> 
    to start adding documents to your signing package. Once documents have been added you can start preparing them for signing.
  </span>
</div>

<ng-template #orderHasDocs>
  <section>
    <header>
      <section>
        <h2>Document Editor</h2>
        <p>Click on a document below to prepare it for signing.</p>
      </section>
      <div class="actions">
        <div class="actions__upper">
          <span>View:</span>
          <mat-button-toggle-group
            name="digiDocsSection"
            (change)="handleDigiDocsSectionChange($event)"
            [value]="digiDocsSection"
          >
            <mat-button-toggle value="Thumbnails">
              <div class="grid-icon__container">
                <div class="grid-icon__wrapper">
                  <i
                    [ngClass]="{
                      'fa-th--toggled': digiDocsSection === 'Thumbnails'
                    }"
                    aria-hidden="true"
                    class="fa fa-th fa-inverse"
                  ></i>
                </div>
              </div>
              Tiled
            </mat-button-toggle>
            <mat-button-toggle value="List">
              <i class="fa fa-bars" aria-hidden="true"></i>
              List
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="actions__lower" *ngIf="canViewVoidedDocuments">
          <mat-slide-toggle
            (change)="handleSlideToggleChange($event)"
          ></mat-slide-toggle>
          <span>{{(showVoidedDocuments$ | async) ? 'Show' : 'Hide'}} voided documents</span>
        </div>
      </div>
    </header>
  </section>
</ng-template>
