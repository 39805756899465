<div>
  <div class="add-commission">
    <div class="header">
      <h2 *ngIf="mode === 'edit'" class="title">Edit Commission for {{ commissionState.name }}</h2>
      <h2 *ngIf="mode === 'add'" class="title">Add Commission for {{ commissionState.name }}</h2>
    </div>

    <ng-container>
      <div class="modal-body">
        <form [formGroup]="commissionForm" class="nex-form">
          <div class="disclaimer">*Required Information</div>
          <div class="row">
            <div class="col-12 label">
              Location
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="fill" class="clrsgn-form-field">
                <mat-label for="county" class="clrsgn-label">County</mat-label>
                <mat-select formControlName="county" id="county">
                  <mat-option *ngFor="let county of countyDropDownList"
                              [value]="county.countyFIPSCode">
                    {{ county.countyName }}
                  </mat-option>
                </mat-select>
                <app-form-error [group]="commissionForm" [controlName]="'county'"></app-form-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="countySelected; else noCommissionLocation">
            <div class="col-12 label">
              Details
            </div>
          </div>
          <ng-container *ngIf="countySelected; else noCommissionLocation">
            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="fill" class="clrsgn-form-field">
                  <mat-label for="commissionName" class="clrsgn-label">Name on Commission</mat-label>
                  <input matInput
                         type="text"
                         formControlName="commissionName"
                         id="commissionName" />
                  <app-form-error [group]="commissionForm" [controlName]="'commissionName'"></app-form-error>
                </mat-form-field>
              </div>
              <ng-container *ngIf="(selectedRule | async)?.isCommissionNumberRequired">
                <div class="col-6">
                  <mat-form-field appearance="fill" class="clrsgn-form-field">
                    <mat-label for="commissionNumber" class="clrsgn-label">Commission #<span class="required-indicator">*</span></mat-label>
                    <input matInput
                           type="text"
                           formControlName="commissionNumber"
                           id="commissionNumber" />
                    <app-form-error [group]="commissionForm" [controlName]="'commissionNumber'"></app-form-error>
                  </mat-form-field>
                </div>
              </ng-container>
              <div class="col-6">
                <mat-form-field appearance="fill" class="clrsgn-form-field">
                  <mat-label for="commissionExpiration" class="clrsgn-label">
                    Expiration Date
                  </mat-label>
                  <input matInput
                         [matDatepicker]="scheduledDatepicker"
                         [min]="minDate"
                         formControlName="commissionExpiration"
                         id="commissionExpiration"
                         [formGroup]="commissionForm" />
                  <mat-datepicker-toggle matSuffix [for]="scheduledDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #scheduledDatepicker></mat-datepicker>
                  <app-form-error [group]="commissionForm"
                                  [controlName]="'commissionExpiration'"></app-form-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="!((selectedRule | async)?.isAutoGeneratedStampAllowed)">
              <div class="col-6">
                <div class="form-group">
                  <label>Stamp:</label>
                  <app-file-upload id="stampFileUpload" formControlName="stamp" (change)="onFileSelected($event, 'stamp')"></app-file-upload>
                  <app-form-error [group]="commissionForm"
                                  [controlName]="'stamp'"
                                  [customErrorMessage]="'Please select a .PNG file'"></app-form-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <ng-container *ngIf="!stampHasErrors && (hasStamp || (stampUploaded | async))">
                  <label>Stamp Preview:</label> <br />
                  <div *ngIf="selectedStampBase64">
                    <img [src]="getSanitizedImage(selectedStampBase64)" class="stamp-image" alt="" />
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="footer">
        <button type="button" class="amr-btn-outline-primary me-3" (click)="close()">Cancel</button>
        <button type="button" class="amr-btn-default" (click)="saveCommission()">Save</button>
      </div>
    </ng-container>
  </div>
</div>
