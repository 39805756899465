<ng-container>
  <table class="table table-striped marginTopTwentyPx" aria-label="Participant List">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Role</th>
        <th scope="col">Email Address</th>
        <th scope="col">Participant Link</th>
        <th scope="col">Pre-Sign Status</th>
        <th *ngIf="showActions" scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let participant of participants" data-cy="participantRecord">
        <td [attr.data-cy]="'participant-' + participant.id"
            class="participant-column"
            *ngIf="participant.participantModifiable as participantName">
          <div ngbTooltip="{{ participantName | participantDisplayNameTooltip }}">
            {{ participantName | participantDisplayName }}
          </div>
        </td>
        <td>{{ getFriendlyRole(participant) }}</td>
        <td>{{ participant.participantModifiable.email }}</td>
        <td>
          <div [showIfHasCapability]="[
              {
                capabilityString: 'CanGetAllSessionLinks',
                type: 'order',
                id: orderId
              },
              {
                capabilityString: 'CanGetMySessionLink',
                type: 'participant',
                id: participant.id
              }
            ]">
            <ng-container *ngIf="participantHasLink(participant) && canGetLink$ | async">
              <button type="button"
                      class="btn btn-link nexsys-green"
                      (click)="handleGeturlAction(participant)">
                Get Link
              </button>
            </ng-container>
          </div>
        </td>
        <td>
          {{ translateParticipantIdToPreSignStatus(participant.id) }}
        </td>
        <td *ngIf="showActions">
          <action-dropdown-button (actionCalled)="handleActionCall($event)"
                                  [actions]="getActionsForUser(participant) | async"></action-dropdown-button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
