<ng-container>
  <ng-container *ngIf="(digitizationParameters$ | async) as digitizationParameters">
    <form [formGroup]="docUploadForm" (ngSubmit)="uploadDocument()">
    <div class="modal-header document-upload-size">
      <h5 class="modal-title" id="documentInformationModelLabel">Upload Document</h5>
    </div>
    <div class="modal-body formContainer">
      <div class="document-upload-background">
        <div class="col-12 text-start helpfulBlurb">
          <h6>Separate Legal and Letter Documents</h6>
          <p>The pages within the document being uploaded should only contain Legal or Letter sized pages, not both.</p>
        </div>
        <div class="col-12 text-start helpfulBlurb">
          <h6>Check for uniform page size</h6>
          <p>Please make sure the pages within the document are correctly sized as Legal (8.5" x 14") or Letter (8.5" x 11") paper size.</p>
        </div>
        <div class="col-12 text-start helpfulBlurb">
          <h6>Split out documents</h6>
          <p>If there are several pages within the document package, consider separating the document into smaller individual documents.</p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="form-group">
            <p>*Required Information</p>
            <label>Upload file to digitize</label>
            <app-file-upload
              (change)="handleFileChange()"
              formControlName="documentFile"
              dragDropText="digidocs.uploadForm.dropFileHere">
            </app-file-upload>
            <mat-error *ngIf="isFieldInvalidDirty(docUploadForm, 'documentFile')">
              {{getErrorMessage(docUploadForm, 'documentFile')}}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label for="documentName">{{'documentName'}}</mat-label>
              <input matInput
                    formControlName="documentName"
                    required
                    maxlength="200"
                    data-cy="documentName"
              >
              <mat-error *ngIf="isFieldInvalidDirty(docUploadForm, 'documentName')">
                {{getErrorMessage(docUploadForm, 'documentName')}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label for="documentType">{{'documentType'}}</mat-label>
              <input matInput
                formControlName="documentType"
                maxlength="32"
                data-cy="documentType"
              />
              <mat-error *ngIf="isFieldInvalidDirty(docUploadForm, 'documentType')">
                {{getErrorMessage(docUploadForm, 'documentType')}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label for="actionType">{{'actionType'}}</mat-label>
              <input matInput type="text" formControlName="actionType" class="form-control" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <h6>Document Format</h6>
          <div class="form-group" *ngIf="(imageData$ | async) as imageData">
            <mat-form-field appearance="fill">
              <mat-label for="paperSize">{{'paperSize'}}</mat-label>
              <mat-select
                formControlName="paperSize"
                placeholder="Select Paper Size"
                data-cy="paperSizeDropdown">
                <mat-option *ngFor="let paperSize of digitizationParameters.paperSizes" [value]="paperSize">{{ paperSize.value }}</mat-option>
                <mat-error *ngIf="isFieldInvalidDirty(docUploadForm, 'paperSize')">
                  {{getErrorMessage(docUploadForm, 'paperSize')}}
                </mat-error>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <h6>Apply Template</h6>
          <mat-form-field appearance="fill">
            <mat-label for="template">Template To Apply</mat-label>
            <mat-select
              formControlName="template"
              placeholder="Template To Apply"
              data-cy="templateDropdown">
              <mat-option *ngFor="let template of (templateOptions$ | async)" [value]="template.key">{{ template.value }}</mat-option>
              <mat-error *ngIf="isFieldInvalidDirty(docUploadForm, 'template')">
                {{getErrorMessage(docUploadForm, 'template')}}
              </mat-error>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="amr-btn-outline-primary" (click)="closeUploadDocumentModel('cancel')">Cancel</button> &nbsp;
      <button type="button" class="amr-btn-default" [disabled]="docUploadForm?.invalid" (click)="getPngFromPdfjs(1 + (1/3))">PDF.js Document</button>
      <button type="submit" class="amr-btn-default" [disabled]="docUploadForm?.invalid">Upload Document</button>
    </div>
  </form>
  </ng-container>
</ng-container>
