<ng-container>
  <ng-container *ngIf="(digitizationParameters$ | async) as digitizationParameters">
    <div class="container-fluid" *ngIf="(imageData$ | async) as imageData" [ngClass]="{'documents': (sectionToDisplay$ | async) === 'Documents'}">
      <app-endorsements-sidenav
        (generateEndorsementComponent)="handleEndorsementComponentOutput($event)"
        (generateEndorsementUsingTemplate)="handleGenerateEndorsementUsingTemplate($event)"
        (generateNotaryEndorsementComponent)="handleGenerateNotaryEndorsementComponent($event)"
        (openSaveTemplateModalForTemplateName)="handleOpenSaveTemplateModalForTemplateName($event)"
        (submitDocumentAndEndorsements)="handleSubmitDocumentAndEndorsements($event)"
        (updateTemplateEndorsements)="handleUpdateTemplateEndorsements($event)"
        [digitizationParameters$]="digitizationParameters$"
        [imageData$]="imageData$"
        [order]="order"
        [participants$]="participants$"
        [templateID]="templateID"
        [templateOptions$]="templateOptions$"
        *ngIf="(sectionToDisplay$ | async) === 'Documents'"
      ></app-endorsements-sidenav>
      <div #documentDisplayContainer class="row">
        <div class="ribbon-bar" [ngClass]="{'documents': (sectionToDisplay$ | async) === 'Documents'}">
          <ng-container *ngIf="(ribbonTabToDisplay$ | async) as ribbonTabToDisplay">
            <ng-container *ngIf="ribbonTabToDisplay === 'File'">
              <div class="ribbon-collection-file" *ngIf="!isOrderLocked">
                <div class="ribbon-collection-items">
                  <div class="ribbon-item-upload">
                    <button
                      class="btn center amr-btn-default"
                      (click)="openUploadDocumentModel()"
                      [showIfHasCapability]="[
                        {
                          capabilityString: 'CanAddDocument',
                          type: 'order',
                          id: order?.id
                        }
                      ]"
                      >
                      <span class="fas fa-arrow-up-from-bracket"></span>
                      Upload Document
                    </button>
                  </div>
                </div>
              </div>
              <a class="nav-link-text float-end nav-link-text-bold" (click)="exitDocumentEditor()"><span class="fas fa-arrow-right-from-bracket ribbon-button-icon"></span> Exit Document Editor</a>
            </ng-container>
            <div *ngIf="ribbonTabToDisplay === 'Document'">
              <app-document-edit-form 
                [order]="order"
                (exitDocumentEditor)="exitDocumentEditor()"
                (updateReviewedByAttribute)="updateReviewedByAttribute($event)"
              >
              </app-document-edit-form>
            </div>
            <div id="applyTab" *ngIf="ribbonTabToDisplay === 'Apply'">
              <div class="ribbon-collection no-border">
                <div class="ribbon-collection-items">
                  <div class="ribbon-item-row">
                    <div class="ribbon-item fullwidth">
                      <mat-form-field id="digiDocTemplateId" class="clrsgn-form-field" appearance="fill">
                        <mat-label class="clrsgn-label">Apply Template</mat-label>
                        <mat-select data-cy="selectTemplateDropdown" [(ngModel)]="digitizationParameters.selectedTemplate"
                                    (selectionChange)="generateEndorsementsUsingTemplate($event.value, imageData)">
                          <mat-option disabled *ngIf="(templateOptions$ | async).length === 0">No Template Created</mat-option>
                          <mat-option *ngFor="let template of (templateOptions$ | async)" [value]="template.key">{{ template.value }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span *ngIf="templateID">Template ID: {{ templateID }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ribbon-collection no-border">
                <div class="ribbon-collection-items">
                  <div class="ribbon-item-col">
                    <button *ngIf="(participants$ | async) as participants; else saveTemplateAsNotLoaded" class="amr-btn-outline-primary digidoc-button center" (click)="openSaveTemplateModalForTemplateName(imageData, digitizationParameters, participants)">
                      Save Template As
                    </button>
                    <ng-template #saveTemplateAsNotLoaded>
                      <button class="amr-btn-outline-primary digidoc-button center" [disabled]>
                        Save Template As
                      </button>
                    </ng-template>
                    <button class="amr-btn-default digidoc-button center" *ngIf="(participants$ | async) as participants; else saveTemplateChangesNotLoaded" (click)="updateTemplateEndorsements(imageData, digitizationParameters, digitizationParameters.selectedTemplate, participants)">
                      Save Template Changes
                    </button>
                    <ng-template #saveTemplateChangesNotLoaded>
                      <button class="amr-btn-default digidoc-button center" [disabled]>
                        Save Template Changes
                      </button>
                    </ng-template>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="(getParticipants() | async) as participants">
                <div class="ribbon-collection">
                  <div class="ribbon-collection-items">
                    <div class="ribbon-item-col">
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="signatureDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Signature
                          </button>
                          <div ngbDropdownMenu aria-labelledby="signatureDropdown">
                            <button *ngFor="let participant of participants" [disabled]="!participantCanParticipate(participant)" ngbDropdownItem (click)="generateEndorsementComponent(participant, imageData, false, 'SIGNATURE')">
                              {{ participant | participantSignatureName }} - <span class="nex-green-txt">{{participant.role | roleValue}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="initialsDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Initials
                          </button>
                          <div ngbDropdownMenu aria-labelledby="initialsDropdown">
                            <button *ngFor="let participant of participants" [disabled]="!participantCanParticipate(participant)" ngbDropdownItem (click)="generateEndorsementComponent(participant, imageData, false, 'INITIALS')">
                              {{ participant | participantSignatureName }} - <span class="nex-green-txt">{{participant.role | roleValue}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ribbon-item-col">
                      <div class="ribbon-item">
                        <button class="btn text-button" [disabled]="endorsmentDisabledForOrder()" type="button" aria-label="Stamp" (click)="generateNotaryEndorsement(imageData, participants)">
                          Stamp
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="ribbon-collection-name">
                    Endorsements
                  </div>
                </div>
                <div class="ribbon-collection">
                  <div class="ribbon-collection-items">
                    <div class="ribbon-item-col">
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="textboxDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Textbox
                          </button>
                          <div ngbDropdownMenu aria-labelledby="textboxDropdown">
                            <a *ngFor="let participant of participants" ngbDropdownItem [disabled]="!participantCanParticipate(participant)" (click)="generateEndorsementComponent(participant, imageData, false, 'TEXTBOX')">{{ participant | participantDisplayName }} - <span class="nex-green-txt">{{participant.role | roleValue}}</span></a>
                          </div>
                        </div>
                      </div>
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="checkboxDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Checkbox
                          </button>
                          <div ngbDropdownMenu aria-labelledby="checkboxDropdown">
                            <button *ngFor="let participant of participants" ngbDropdownItem [disabled]="!participantCanParticipate(participant)" (click)="generateEndorsementComponent(participant, imageData, false, 'CHECKBOX')">{{ participant | participantDisplayName }} - <span class="nex-green-txt">{{participant.role | roleValue}}</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ribbon-item-col">
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="textboxOptionalDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Textbox - Optional
                          </button>
                          <div ngbDropdownMenu aria-labelledby="textboxOptionalDropdown">
                            <button *ngFor="let participant of participants" ngbDropdownItem [disabled]="!participantCanParticipate(participant)" (click)="generateEndorsementComponent(participant, imageData, false, 'TEXTBOX', null, 0, 0, 0, 0, false)">{{ participant | participantDisplayName }} - <span class="nex-green-txt">{{participant.role | roleValue}}</span></button>
                          </div>
                        </div>
                      </div>
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="checkboxOptionalDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Checkbox - Optional
                          </button>
                          <div ngbDropdownMenu aria-labelledby="checkboxOptionalDropdown">
                            <button *ngFor="let participant of participants" ngbDropdownItem [disabled]="!participantCanParticipate(participant)" (click)="generateEndorsementComponent(participant, imageData, false, 'CHECKBOX', null, 0, 0, 0, 0, false)">
                              {{ participant | participantDisplayName }} - <span class="nex-green-txt">{{participant.role | roleValue}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ribbon-collection-name">
                    Inputs
                  </div>
                </div>
                <div class="ribbon-collection">
                  <div class="ribbon-collection-items">
                    <div class="ribbon-item-col">
                      <div class="ribbon-item">
                        <button class="btn text-button" type="button" aria-label="State" (click)="generateEndorsementComponent(null, imageData, true, 'STATE')">
                          State
                        </button>
                      </div>
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" type="button" id="dateDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Date
                          </button>
                          <div ngbDropdownMenu aria-labelledby="dateDropdown">
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'MM/dd/yyyy')">Full Date (MM/dd/yyyy)</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'yyyy')">Four Digit Year (yyyy)</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'yy')">Two Digit Year (yy)</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'dd')">Day Of Month (dd)</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'MMMM')">Month Name (January)</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'MMM')">Month Abbreviated (Jan)</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'DATE', 'MM')">Month Number (MM)</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ribbon-item-col">
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" [disabled]="endorsmentDisabledForOrder()" type="button" id="signingAgentFieldsdropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Signing Agent Fields
                          </button>
                          <div ngbDropdownMenu aria-labelledby="signingAgentFieldsdropdown">
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'SIGNINGAGENTCOUNTY')">Signing Agent County</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'SIGNINGAGENTNAME')">Signing Agent Name</button>
                          </div>
                        </div>
                      </div>
                      <div class="ribbon-item">
                        <div ngbDropdown>
                          <button class="btn text-button dropdown-toggle" [disabled]="endorsmentDisabledForOrder()" type="button" id="commissionFieldsDropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            Commission Fields
                          </button>
                          <div ngbDropdownMenu aria-labelledby="commissionFieldsDropdown">
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'SIGNINGAGENTCOMMEXPIRE')">Commission Expiration Date</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'SIGNINGAGENTCOMMNUMBER')">Commission Number</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'SIGNINGAGENTCOMMCOUNTY')">Commission County</button>
                            <button ngbDropdownItem (click)="generateEndorsementComponent(null, imageData, true, 'SIGNINGAGENTCOMMSTATE')">Commission State</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ribbon-collection-name">
                    System Generated
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="(sectionToDisplay$ | async) as sectionToDisplay">
          <app-sub-header
            (handleGetAllDigitizableDocuments)="getAllDigitizableDocuments($event)"
            *ngIf="!isOrderLocked && (sectionToDisplay === 'List' || sectionToDisplay === 'Thumbnails')"
          ></app-sub-header>
          <app-document-editor-list
            *ngIf="!isOrderLocked && sectionToDisplay === 'List'"
            (deleteByDocumentId)="handleDeleteByDocumentId($event)"
            (downloadByDocumentId)="handleDownloadByDocumentId($event)"
            (displaySelectedDocumentId)="displaySelectedDocument(
              $event,
              digitizationParameters.myDocuments,
              digitizationParameters.paperSizes
            )"
          ></app-document-editor-list>
          <app-document-editor-thumbnails
            *ngIf="!isOrderLocked && sectionToDisplay === 'Thumbnails'"
            (deleteByDocumentId)="handleDeleteByDocumentId($event)"
            (downloadByDocumentId)="handleDownloadByDocumentId($event)"
            (displaySelectedDocumentId)="displaySelectedDocument(
              $event,
              digitizationParameters.myDocuments,
              digitizationParameters.paperSizes
            )"
          >
          </app-document-editor-thumbnails>
          <section class="documentdisplay"
                   *ngIf="sectionToDisplay === 'Documents'">
            <div class="documents-flex"></div>
            <div class="imageContainerSection">
                <div class="imageContainer">
                  <img #docImage [src]="imageData.src" [hidden]="!imageData.src" class="documentImageCss" alt="" />
                  <ng-container *ngIf="(imageOverlayDivDimensions$ | async) as imageOverlayDimensions">
                    <div #imageDivOverlay>
                      <ng-container #ec></ng-container>
                    </div>
                  </ng-container>
                </div>
            </div>
            <div class="documents-flex"></div>
          </section>
        </ng-container>
      </div>
    </div>
  <ng-template #loading>
    Page Loading
  </ng-template>
  </ng-container>
</ng-container>
