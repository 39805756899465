<ng-container>
  <div
    class="loan-information-section"
    *ngIf="order$ | async as order; else initialLoad"
  >
    <div class="mb-4">
      <h2>Key Info</h2>
    </div>
    <div class="row" *ngIf="!toggleLoanInformationFormEdit">
      <div class="col">
        <label>Property Address</label>
        <div *ngIf="order?.propertyAddress; else loading">
          <div data-cy="streetAddress">
            {{ order?.propertyAddress.streetAddress1 }}
          </div>
          <div data-cy="cityStateZip">
            {{ order?.propertyAddress.city }}
            {{ order?.propertyAddress.stateCode }}
            {{ order?.propertyAddress.zipCode }}
          </div>
        </div>
      </div>
      <div class="col">
        <label>Signing Type</label>
        <div
          *ngIf="order; else loading"
          data-cy="signingType"
        >
          <span>{{ order?.productType | closingTypeValue }}</span>
        </div>
      </div>
      <div class="col">
        <label>Scheduled Date/Time</label>
        <div
          *ngIf="order?.scheduledDateTime; else loading"
          data-cy="scheduledDateTime"
        >
          <span>{{ scheduledTime$ | async | date: "medium" }}</span>
        </div>
      </div>
      <div class="col">
        <label>Order Status</label>
        <ng-container *ngIf="orderStatus$ | async as orderStatus">
          <button
            *ngIf="orderStatus == 'OPTOUT' || orderStatus == 'CANCELLED'"
            type="button"
            class="btn btn-link nexsys-green view-comments-btn"
            (click)="openCommentsModal()"
          >
            (View Comments)
          </button>
          <ng-container
            [ngTemplateOutlet]="
              (orderStatus === 'CANCELLED' || orderStatus === 'OPTOUT') &&
              order?.orderStatusDetail?.categoryTypeValue
                ? cancelledOrOptOutOrderStatus
                : defaultOrderStatus
            "
            [ngTemplateOutletContext]="{
              order: order,
              orderStatus: orderStatus
            }"
          ></ng-container>
        </ng-container>
      </div>
      <div class="col">
        <label>Loan Number</label>
        <div data-cy="loanIdentifier">
          {{ order?.loanIdentifier }}
        </div>
      </div>
      <div class="col">
        <label>3rd Party Order ID</label>
        <div data-cy="thirdPartyOrderIdentifier">
          {{ order?.thirdPartyOrderIdentifier }}
        </div>
      </div>
      <div class="col">
        <label>Pre-Sign</label>
        <div *ngIf="order; else loading">
          <span
            ><span
              *ngIf="isOrderPreSign"
              class="fas fa-check-circle nex-green-txt stand-alone-icon-tables"
              data-cy="presignEnabled"
            ></span
          ></span>
        </div>
      </div>
      <div *ngIf="isOrderManager && (isPortalAssigneeEnabled$ | async)" class= "col">
        <label>Assignee</label>
        <ng-select
        class="assignee-dropdown"
        [items]="(assignees$ | async)"
        bindLabel="fullName"
        [virtualScroll]="true"
        [clearable]="false"
        [searchFn]="assigneeSearchFn"
        bindValue="accountId"
        labelForId="assignees"
        placeholder="No assignee"
        (change)="setAssignee($event)"
        [placeholder]="currentAssigneeName"
        >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="text-truncate"
            ngbTooltip="{{ item.fullName }} {{item.emailAddress}}"
            placement="right"
            container="body"
            openDelay="500"
          >
          <span class="fw-bold">{{item.fullName}}</span> <div class="emailAddress text-truncate">{{item.emailAddress}}</div>
        </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-search="searchTerm" let-index="index">
          <div class="text-truncate">
            {{item.fullName}}
          </div>
        </ng-template>
      </ng-select>
      </div>
    </div>
  </div>
  <ng-template #loading>
    <ion-skeleton-text animated></ion-skeleton-text>
  </ng-template>
  <ng-template #initialLoad>
    <div class="loan-information-section">
      <div
        class="row marginTopFifteenPx"
        *ngIf="!toggleLoanInformationFormEdit"
      >
        <div class="col">
          <label>Property Address</label>
          <ion-skeleton-text animated></ion-skeleton-text>
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
        <div class="col">
          <label>Signing Type</label>
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
        <div class="col">
          <label>Scheduled Date/Time</label>
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
        <div class="col">
          <label>Order Status</label>
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
        <div class="col">
          <label>Pre-Sign</label>
          <ion-thumbnail slot="start" style="height: 1rem; width: 1rem">
            <ion-skeleton-text></ion-skeleton-text>
          </ion-thumbnail>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template
    #defaultOrderStatus
    let-orderStatus="orderStatus"
    let-order="order"
  >
    <div data-cy="orderStatus">
      <span>{{ orderStatus | titleCaseStatusCode }}</span>
    </div>
  </ng-template>
  <ng-template
    #cancelledOrOptOutOrderStatus
    let-orderStatus="orderStatus"
    let-order="order"
  >
    <ng-container *ngIf="orderStatusDetails$ | async as orderStatusDetails">
      <div
        *ngFor="let detail of orderStatusDetails.orderStatusDetails"
        data-cy="orderStatus"
      >
        <span
          >{{ orderStatus | titleCaseStatusCode }} -
          {{ detail.categoryTypeValue }}</span
        >
      </div>
    </ng-container>
  </ng-template>
</ng-container>
