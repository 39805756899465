<ng-container>
  <div *ngIf="!(isOrderLocked$ | async)"
       [showIfHasCapability]="[
      {
        capabilityString: 'CanDigitizeDocuments',
        type: 'order',
        id: order?.id
      }
    ]">
    <h5 class="float-start">Documents in Package</h5>
  </div>

  <table mat-table
         #table
         [dataSource]="documents"
         aria-label="Documents in Package"
         class="table-responsive doc-list-width doc-list-height doc-list-design">
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef style="width: 60%"></th>
      <td [attr.data-cy]="'doc-' + doc.id" mat-cell *matCellDef="let doc">
        <span [allowLinkIfHasCapability]="[
          {
            capabilityString: 'CanEditDocument',
            type: 'document',
            id: doc?.id
          }
        ]"
              (click)="openDocumentEditor(documents, doc.id, doc.isSmartDoc)"
              class="document-text bold-click force-wrap"
              [ngClass]="{ 'order-locked': (isOrderLocked$ | async) }">{{ doc.name }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef style="width: 20%"></th>
      <td mat-cell *matCellDef="let doc">
        <span [ngClass]="{ 'fas fa-exclamation-triangle action-icon' : (doc.status === 'Needs Attention'),
             'pad-empty' : (doc.status != 'Needs Attention')}">
        </span>
        <span class="fas fa-download action-icon"
              [allowLinkIfHasCapability]="[
            {
              capabilityString: 'CanReadDownloadMyDocument',
              type: 'document',
              id: doc?.id
            },
            {
              capabilityString: 'CanReadNBTHExecutedClosingPackage',
              type: 'document',
              id: doc?.id
            }
          ]"
              (click)="displayPackageDocument(doc.id, doc.isSmartDoc, doc.name)">
        </span>
        <span *ngIf="!(isOrderLocked$ | async)"
              class="fas fa-trash-alt action-icon cursor-hand"
              [shouldRemove]="
            capabilitiesService.userHasCapability(
              'document',
              'CanRemoveDocument',
              doc?.id
            ) | async
          "
              (click)="onDeleteDocument(doc.id, doc.status)">
        </span>
        <span *ngIf="doc.status.toUpperCase() === 'SIGNED'"
              class="fa-solid fa-file-signature action-icon"
              title="Signed">
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
