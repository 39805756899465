<ng-container>
  <div class="modal-body">
    <form [formGroup]="participantInformationForm" class="clrsgn-form">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="participantRole" class="clrsgn-label">
              Participant Role
            </mat-label>
            <input matInput
                   *ngIf="isEditing$ | async"
                   formControlName="participantRole"
                   [readonly]="true" />
            <mat-select *ngIf="!(isEditing$ | async)"
                        formControlName="participantRole"
                        id="participantRole"
                        data-cy="participantRoleDropdownList">
              <mat-option *ngFor="let userRole of userRoles"
                          [value]="userRole.key">
                {{ userRole.value }}
              </mat-option>
            </mat-select>
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'participantRole'"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="sequenceNumber" class="clrsgn-label">
              Sequence Number
            </mat-label>
            <mat-select formControlName="sequenceNumber"
                        id="sequenceNumber"
                        data-cy="sequenceNumberDropdownList">
              <mat-option *ngFor="let sequenceNumber of sequenceNumbers"
                          [value]="sequenceNumber">
                {{ sequenceNumber }}
              </mat-option>
            </mat-select>
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'sequenceNumber'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="emailAddress" class="clrsgn-label">
              Email Address
            </mat-label>
            <input matInput
                   maxlength="128"
                   type="text"
                   formControlName="emailAddress"
                   id="emailAddress" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'emailAddress'"
                            [customErrorMessage]="customErrorMessage"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="confirmEmailAddress" class="clrsgn-label">
              Confirm Email Address
            </mat-label>
            <input matInput
                   maxlength="128"
                   type="text"
                   formControlName="confirmEmailAddress"
                   id="confirmEmailAddress" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'confirmEmailAddress'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="isMultiFactorEnabled()" class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="phoneNumber" class="clrsgn-label">
              Phone Number
            </mat-label>
            <input matInput
                   maxlength="128"
                   type="tel"
                   formControlName="phoneNumber"
                   id="phoneNumber"
                   prefix="+1"
                   mask="(000) 000-0000"
                   pattern="\d*"
                   inputmode="numeric" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'phoneNumber'"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="verificationType" class="clrsgn-label">
              Verification Method
            </mat-label>
            <mat-select formControlName="verificationType"
                        id="verificationType"
                        data-cy="verificationMethodDropdownList">
              <mat-option *ngFor="let verificationType of verificationTypes"
                          [value]="verificationType">
                {{ verificationType }}
              </mat-option>
            </mat-select>
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'verificationType'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="firstName" class="clrsgn-label">
              First Name
            </mat-label>
            <input matInput
                   maxlength="200"
                   type="text"
                   formControlName="firstName"
                   id="firstName" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'firstName'"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="lastName" class="clrsgn-label">
              Last Name
            </mat-label>
            <input matInput
                   maxlength="200"
                   type="text"
                   formControlName="lastName"
                   id="lastName" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'lastName'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="prefix" class="clrsgn-label">
              Prefix
            </mat-label>
            <input matInput
                   maxlength="200"
                   type="text"
                   formControlName="prefix"
                   id="prefix" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="suffix" class="clrsgn-label">
              Suffix
            </mat-label>
            <input matInput
                   maxlength="200"
                   type="text"
                   formControlName="suffix"
                   id="suffix" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="signatureName" class="clrsgn-label">
              Signature Name
            </mat-label>
            <input matInput
                   maxlength="250"
                   type="text"
                   formControlName="signatureName"
                   id="signatureName" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'signatureName'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="partnerParticipantIdentifier" class="clrsgn-label">
              Company Identifier
            </mat-label>
            <input matInput
                   maxlength="12"
                   type="text"
                   formControlName="partnerParticipantIdentifier"
                   id="partnerParticipantIdentifier" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <div *ngIf="!(isEditing$ | async)">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="lastFourSsn" class="clrsgn-label">
                Last 4 of SSN
              </mat-label>
              <input matInput
                     maxlength="4"
                     type="text"
                     formControlName="lastFourSsn"
                     pattern="\d*"
                     inputmode="numeric"
                     id="lastFourSsn" />
              <app-form-error [group]="participantInformationForm"
                              [controlName]="'lastFourSsn'"></app-form-error>
            </mat-form-field>
          </div>
          <div *ngIf="(isEditing$ | async) && !isEditingSSN" class="edit-ssn">
            [<a class="clrsgn-link" (click)="editParticipantSsn()">Edit SSN</a>]
          </div>
          <div *ngIf="(isEditing$ | async) && isEditingSSN">
            <mat-form-field appearance="fill" class="clrsgn-form-field">
              <mat-label for="lastFourSsn" class="clrsgn-label">
                Last 4 of SSN
              </mat-label>
              <input matInput
                     maxlength="4"
                     type="text"
                     formControlName="lastFourSsn"
                     pattern="\d*"
                     inputmode="numeric"
                     id="lastFourSsn" />
              <app-form-error [group]="participantInformationForm"
                              [controlName]="'lastFourSsn'"></app-form-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <p class="residence-text">Current Residence</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="propertyAddress1" class="clrsgn-label">
              Street Address
            </mat-label>
            <input matInput
                   maxlength="250"
                   type="text"
                   formControlName="propertyAddress1"
                   id="propertyAddress1" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'propertyAddress1'"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="propertyAddress2" class="clrsgn-label">
              Street Address 2
            </mat-label>
            <input matInput
                   maxlength="50"
                   type="text"
                   formControlName="propertyAddress2"
                   id="propertyAddress2" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="city" class="clrsgn-label">
              City
            </mat-label>
            <input matInput
                   maxlength="50"
                   type="text"
                   formControlName="city"
                   id="city" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'city'"></app-form-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="propertyState" class="clrsgn-label">
              State
            </mat-label>
            <mat-select formControlName="propertyState"
                        id="propertyState"
                        data-cy="stateDropdownList">
              <mat-option *ngFor="let state of selectedStates"
                          [value]="state.code">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'propertyState'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="zip" class="clrsgn-label">
              Zip
            </mat-label>
            <input matInput
                   maxlength="5"
                   type="text"
                   formControlName="zip"
                   id="zip"
                   pattern="\d*"
                   inputmode="numeric" />
            <app-form-error [group]="participantInformationForm"
                            [controlName]="'zip'"></app-form-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="clrsgn-btn-outline-primary"
            data-bs-dismiss="modal"
            (click)="resetModal('cancel')">
      Cancel
    </button>
    <button type="button"
            class="clrsgn-btn-default"
            (click)="updateParticipant()"
            *ngIf="isEditing$ | async">
      Save Participant
    </button>
    <button type="button"
            class="clrsgn-btn-default"
            data-bs-dismiss="modal"
            (click)="addParticipant()"
            *ngIf="!(isEditing$ | async)">
      Add Participant
    </button>
  </div>
</ng-container>
