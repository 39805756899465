import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { AppDialogModule } from "src/app/shared/components/app-dialog/app-dialog.component";
import { AppSpinnerModule } from "src/app/shared/modules/app-spinner.module";

import { AccountService } from "../shared/services/account.service";
import { AddUserModalComponent } from "./components/add-user-modal/add-user-modal.component";
import { ClientAccount, ClientAccounts, NewClientAccount } from "../shared/models/account";
import { DisplayClientAccount } from "../shared/models/display-client-accounts";
import { DisplayRole } from "../shared/models/display-role";
import { Pagination } from "../shared/models/pagination";
import { PaginationComponent } from "../manage-orders/components/pagination/pagination.component";

@UntilDestroy()
@Component({
  selector: 'manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
  
  @ViewChild(PaginationComponent) paginator:  PaginationComponent;
  accountRoles: DisplayRole[] = [
    {
      value: "CLIENTADMIN",
      displayValue: "Client Admin",
    },
    {
      value: "ORDERMANAGER",
      displayValue: "Order Manager",
    },
    {
      value: "SETTLEMENTAGENT",
      displayValue: "Settlement Agent",
    },
    {
      value: "SIGNINGAGENT",
      displayValue: "Signing Agent",
    },
  ];
  clientAccounts: DisplayClientAccount[] = [];
  clientAccountsDisplay: DisplayClientAccount[] = [];
  currentUserAccountId: number;
  currentClientId: number;
  currentClientName: string;
  currentClientEmail: string;
  currentUserClientAdminAccounts$: Observable<ClientAccounts>;
  filterAccountRoles: DisplayRole[] = [
    {
      value: "CLIENTADMIN",
      displayValue: "Client Admin",
    },
    {
      value: "ORDERMANAGER",
      displayValue: "Order Manager",
    },
    {
      value: "SETTLEMENTAGENT",
      displayValue: "Settlement Agent",
    },
    {
      value: "SIGNINGAGENT",
      displayValue: "Signing Agent",
    },
    {
      value: "",
      displayValue: "All Roles",
    },
  ];
  isLoading = true;
  pagination: Pagination = {
    canMoveBackward: false,
    canMoveForward: false,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  };
  searchForm: UntypedFormGroup;
  sortAscending = true;
  sortColumn: string;

  constructor(
    private readonly accountService: AccountService,
    private readonly dialog: AppDialogModule,
    private readonly modalService: NgbModal,
    private readonly spinner: AppSpinnerModule,
    private readonly toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.initSearchFilters();
    this.getClientLists();
  }

  initSearchFilters(): void {
    this.searchForm = new UntypedFormGroup({
      searchName: new UntypedFormControl("", Validators.maxLength(100)),
      client: new UntypedFormControl(""),
      searchEmail: new UntypedFormControl("", Validators.maxLength(100)),
      roleFilter: new UntypedFormControl("")
    });
  }

  getClientLists(): void {
    this.currentUserClientAdminAccounts$ = this.accountService
      .getClientAccounts()
      .pipe(
        map((clientAccounts) => {
          return {
            clients: clientAccounts.clients?.filter((clientAccount) => clientAccount.accountType.toUpperCase() === "CLIENTADMIN"),
          };
        }),
        tap((clientAccounts) => {
          if(clientAccounts.clients.length > 0) {
            this.searchForm
              .get("client")
              ?.patchValue(clientAccounts.clients[0]?.clientId);
            this.currentUserAccountId = clientAccounts.clients[0]?.accountId;
            this.currentClientId = clientAccounts.clients[0]?.clientId;
            this.currentClientName = clientAccounts.clients[0]?.clientName;
            this.currentClientEmail = clientAccounts.clients[0]?.clientEmail;
            this.retrieveClientAccounts();
          }
        })
      );
  }

  retrieveClientAccounts(): void {
    this.spinner.show();

    this.isLoading = true;
    this.clientAccounts = [];
    this.clientAccountsDisplay = [];

    this.accountService
      .getClientAccountsFromClientId(this.currentClientId)
      .pipe(
        tap((clientAccounts) => {
          clientAccounts.clients.forEach((clientAccount) => {
            if(clientAccount.accountType.toUpperCase() !== "SERVICEACCOUNT" 
                && clientAccount.accountId !== this.currentUserAccountId
                && !this.clientAccounts.some(account => account.accountId === clientAccount.accountId)) {
              const displayClientAccount: DisplayClientAccount = {
                accountId: clientAccount.accountId,
                clientId: clientAccount.clientId,
                name: `${clientAccount.firstName} ${clientAccount.lastName}`,
                email: `${clientAccount.emailAddress}`,
                roles: []
              };
              clientAccounts.clients.forEach((ca) => {
                if(clientAccount.accountId === ca.accountId) {
                  displayClientAccount.roles.push(ca.accountType.toUpperCase());
                }
              })
              this.clientAccounts.push(displayClientAccount);
            }            
          })

          this.clientAccounts.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : 1);
          this.sortList();

          const roleSearch = this.searchForm.get("roleFilter")?.value;
          if(roleSearch !== "") {
            this.clientAccountsDisplay = this.clientAccounts
              .filter((ca) => ca.roles.includes(roleSearch))
              .filter((ca) => ca.name.toUpperCase().includes(this.searchForm.get("searchName")?.value.toUpperCase()))
              .filter((ca) => ca.email.toUpperCase().includes(this.searchForm.get("searchEmail")?.value.toUpperCase()));
          }
          else {
            this.clientAccountsDisplay = this.clientAccounts
              .filter((ca) => (ca.name.toUpperCase().includes(this.searchForm.get("searchName")?.value.toUpperCase())))
              .filter((ca) => (ca.email.toUpperCase().includes(this.searchForm.get("searchEmail")?.value.toUpperCase())));
          }

          this.pagination.totalPages = Math.ceil(this.clientAccountsDisplay.length / this.paginator.currentItemsPerPage);

          if(this.paginator.currentPage > this.pagination.totalPages && this.pagination.totalPages !== 0) {
            this.paginator.currentPage = this.pagination.totalPages;
          }

          this.pagination.canMoveBackward = this.pagination.totalPages > 0 && this.paginator.currentPage > 1;
          this.pagination.canMoveForward = this.pagination.totalPages > 0 && this.paginator.currentPage < this.pagination.totalPages;

          this.clientAccountsDisplay = this.clientAccountsDisplay.slice(
            (this.paginator.currentPage - 1) * this.paginator.currentItemsPerPage, this.paginator.currentPage * this.paginator.currentItemsPerPage);
          
          this.isLoading = false;
          this.spinner.hide()
        })
      )
      .subscribe();
  }

  handleKeyUp(event: KeyboardEvent, column?: string): void {
    if (event.key === 'Enter') {
      if (column) {
        this.changeSort(column);
      } else {
        this.setSearchText();
      }
    }
  }

  setClient(client: ClientAccount): void {
    this.currentClientId = client.clientId;
    this.currentClientName = client.clientName;
    this.currentClientEmail = client.clientEmail;
    this.retrieveClientAccounts();
  }

  setSearchText(): void {
    this.retrieveClientAccounts();
  }

  setSearchRole(): void {
    this.retrieveClientAccounts();
  }

  sortList(): void {
    if(this.sortAscending === true && this.sortColumn === "email") {
      this.clientAccounts.sort((a, b) => (a.email.toUpperCase() < b.email.toUpperCase()) ? -1 : 1);
    }
    else if(this.sortAscending === false && this.sortColumn === "name") {
      this.clientAccounts.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? -1 : 1);
    }
    else if(this.sortAscending === false && this.sortColumn === "email") {
      this.clientAccounts.sort((a, b) => (a.email.toUpperCase() > b.email.toUpperCase()) ? -1 : 1);
    }
  }

  changeSort(sortColumn: string): void {
    this.sortAscending = !this.sortAscending;
    this.sortColumn = sortColumn;
    this.retrieveClientAccounts();
  }

  editClientAccount(selected: boolean, clientAccount: DisplayClientAccount, role: string): void {
    if(selected === true) {
      const newClientAccount: NewClientAccount = {
        clientId: this.searchForm.get("client")?.value,
        accountType: role
      }
  
      this.accountService.postClientAccount(clientAccount.accountId, newClientAccount).subscribe();
    }
    else {
      this.accountService
      .getClientAccountsFromClientId(clientAccount.clientId)
        .pipe(
          tap((clientAccounts) => {
            const cas = clientAccounts.clients.filter((ca) => ca.accountType.toUpperCase() === role.toUpperCase() && ca.accountId === clientAccount.accountId);
            cas.forEach((ca) => {
              this.accountService.deleteClientAccount(Number(ca.id)).subscribe();         
            })
          })
        )
        .subscribe();
    }
  }

  onDelete(clientAccount: DisplayClientAccount): void {
    this.dialog
      .show({
        title: `Remove ${clientAccount.name} from ${this.currentClientName}`,
        message: "Are you sure you want to remove this user from this client?",
        noButtonText: "Cancel",
        yesButtonText: "Remove User",
      })
      .result.then((response) => {
        if (response === "yes") {
          this.spinner.show();

          this.accountService
          .getClientAccountsFromClientId(clientAccount.clientId)
          .pipe(
            tap((clientAccounts) => {
              const cas = clientAccounts.clients.filter((ca) => ca.accountId === clientAccount.accountId);
              let numAccounts = cas.length;
              cas.forEach((ca) => {
                this.accountService.deleteClientAccount(Number(ca.id)).subscribe(
                  () => {
                    numAccounts--;
                    if(numAccounts === 0) {
                      this.clientAccountsDisplay = this.clientAccountsDisplay.filter((ca) => ca.accountId !== clientAccount.accountId);
                      this.toastr.success("User removed successfully.");
                    }
                  },
                  () => {
                    this.toastr.error("Sorry, an error occurred while removing the User.");
                  }
                );    
              })
            })
          )
          .subscribe(
            () => {
              this.spinner.hide();
            },
            () => {
              this.spinner.hide();
              this.toastr.error("Sorry, an error occurred.");
            }
          );
        }
      });
  }

  onPaginatorChanged() : void {
    this.pagination.pageNumber = this.paginator.currentPage;
    this.pagination.pageSize = this.paginator.currentItemsPerPage;
    this.retrieveClientAccounts();
  }

  openAddUserModal(): void {
    const modal = this.modalService.open(AddUserModalComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });

    modal.componentInstance.clientId = this.currentClientId;
    modal.componentInstance.clientName = this.currentClientName;
  }
}
