<div class="page-header-bar">
  <div class="content85percent">
    <div class="float-start">
      <h1>My Account</h1>
    </div>
  </div>
</div>
<div class="content85percent">
  <div class="row">
    <div class="col-12">
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>
