<ng-container>
  <div class="modal-header">
    <h5 *ngIf="selectedOrderOrganizer" class="modal-title" id="orderOrganizerModalLabel">Edit Order Organizer</h5>
    <h5 *ngIf="!selectedOrderOrganizer" class="modal-title" id="orderOrganizerModalLabel">Add Order Organizer</h5>
  </div>
  <div class="modal-body">
    <form [formGroup]="informationForm" class="clrsgn-form">
      <p>*Required Information</p>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="email" class="clrsgn-label">Email Address</mat-label>
            <input maxlength="128"
                   type="text"
                   matInput
                   formControlName="email"
                   id="email" />
            <app-form-error [group]="informationForm"
                            [controlName]="'email'"
                            [customErrorMessage]="duplicateOrderOrganizerErrorMessage"
                            data-cy="EmailError"></app-form-error>
          </mat-form-field>          
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="confirmEmail" class="clrsgn-label">Confirm Email Address</mat-label>
            <input maxlength="128"
                   type="text"
                   matInput
                   formControlName="confirmEmail"
                   id="confirmEmail" />
            <app-form-error [group]="informationForm" [controlName]="'confirmEmail'" data-cy="ConfirmEmailError"></app-form-error>
          </mat-form-field>          
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="firstName" class="clrsgn-label">First Name</mat-label>
            <input maxlength="200"
                   [readonly]="isSelectedFromClientAccounts"
                   type="text"
                   matInput
                   formControlName="firstName"
                   id="firstName" />
            <app-form-error [group]="informationForm" [controlName]="'firstName'" data-cy="FirstNameError"></app-form-error>
          </mat-form-field>          
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="clrsgn-form-field">
            <mat-label for="lastName" class="clrsgn-label">Last Name</mat-label>
            <input maxlength="200"
                   [readonly]="isSelectedFromClientAccounts"
                   type="text"
                   matInput
                   formControlName="lastName"
                   id="lastName" />
            <app-form-error [group]="informationForm" [controlName]="'lastName'" data-cy="LastNameError"></app-form-error>
          </mat-form-field>          
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="clrsgn-btn-outline-primary"
      data-bs-dismiss="modal"
      (click)="resetModal('cancel')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="clrsgn-btn-default"
      data-bs-dismiss="modal"
      (click)="saveOrderOrganizer()"
    >
      <span *ngIf="selectedOrderOrganizer">Save Order Organizer</span>
      <span *ngIf="!selectedOrderOrganizer">Add Order Organizer</span>
    </button>
  </div>
</ng-container>
