<div>
  <i
    routerLink="/myaccount/manage-commissions" 
    class="fa-solid fa-circle-arrow-left float-start back-icon"
    title="Back to Manage Commissions Page">
  </i>
</div>
<div class="page-header-bar">
  <section class="state-commission">
    <h2>State Commission for {{selectedState.name}}:</h2>
  </section>
</div>
<div class="subheader-text">
  Upload your signature, stamp, and digital certificate with the required key information  (Digital Signature, Non-Repudiation). Use certificates from trusted providers like IdenTrust, ensure the file is in PFX format,
  and check for a valid expiration date. Different states will have varying requirements for signature types.
</div>
<button *ngIf="this.commissionId" class="clrsgn-btn-outline float-end" type="button" (click)="deleteCommission()">
  Delete Commission
</button>
<div class="active-commission-header">
  <h3 *ngIf="hasCommission | async">Active Commission</h3>
  <div class="commission-subtext" *ngIf="hasCommission | async">This is your active commission. Click "Edit" to see more details or to customize.</div>
</div>
<div *ngIf="hasCommission | async">
  <div *ngIf="selectedCommission | async as commission" class="active-commission">
    <mat-card>
      <mat-card-header>
        <section class="image-section" *ngIf="commission.stamp">
          <img [src]="getSanitizedImage(commission.stamp)"
               class="stamp-image"
               alt="Notary Seal for {{ selectedState.name }}" />
        </section>
      </mat-card-header>
      <mat-card-content>
        <div class="headline">
          <span class="bold">Active Commission</span>
        </div>
        <div class="body" *ngIf="(selectedRule | async)?.isCommissionNumberRequired">Commission Number: {{ commission.number }}</div>
        <div class="body">County: {{ commission.county }}</div>
        <div class="body">
          <span *ngIf="isCommissionExpired"><i class="fa-solid fa-triangle-exclamation needs-attention"></i>&nbsp;</span>
          Expires: {{ commission.expiresOn | date: "MM/dd/yyyy" }}
        </div>
      </mat-card-content>
      <mat-card-footer>
        <mat-card-actions align="end">
          <button (click)="openCommissionModal('edit')"
                  class="amr-btn-default">
            Edit
          </button>
        </mat-card-actions>
      </mat-card-footer>
    </mat-card>
    <div *ngIf="ruleLoaded | async">
      <div *ngIf="(selectedRule | async)?.isSignatureUploadEnabled">
        <mat-card *ngIf="commission.signature">
          <mat-card-header>
            <section class="image-section">
              <img [src]="getSanitizedImage(commission.signature)"
                   class="signature-image"
                   alt="Notary Signature for {{ selectedState.name }}" />
            </section>
          </mat-card-header>
          <mat-card-content>
            <div class="headline">
              <span class="bold">Signature</span>
            </div>
            <div class="body">This is your current active signature</div>
          </mat-card-content>
          <mat-card-footer>
            <mat-card-actions align="end">
              <button (click)="openSignatureModal('edit')"
                      class="amr-btn-default edit-button"
                      title="If disabled, please update the commission expiration date first."
                      [disabled]="isCommissionExpired">
                Edit
              </button>
            </mat-card-actions>
          </mat-card-footer>
        </mat-card>
        <mat-card *ngIf="!commission.signature">
          <mat-card-content>
            <div class="headline">
              <span class="bold">
                <i class="fa-solid fa-triangle-exclamation missing-upload"></i>
                No Active Signature
              </span>
            </div>
            <div class="body">Add a signature to complete your commission.</div>
          </mat-card-content>
          <mat-card-footer>
            <mat-card-actions align="end">
              <button class="amr-btn-default"
                      (click)="openSignatureModal('add')"
                      title="If disabled, please update the commission expiration date first."
                      [disabled]="isCommissionExpired">
                + Add Signature
              </button>
            </mat-card-actions>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
    <div *ngIf="ruleLoaded | async">
      <div *ngIf="(selectedRule | async)?.isCertificateUploadEnabled">
        <mat-card *ngIf="commission.certificateMetaDataId">
          <mat-card-content>
            <div class="headline">
              <span class="bold">Active Digital Certificate</span>
            </div>
            <section>
              <span class="fas fa-check nex-green-txt margin-left-five-px"></span>
            </section>
          </mat-card-content>
          <mat-card-footer>
            <mat-card-actions align="end">
              <button (click)="openCertificateModal('edit')"
                      class="amr-btn-default edit-button"
                      title="If disabled, please update the commission expiration date first."
                      [disabled]="isCommissionExpired">
                Edit
              </button>
            </mat-card-actions>
          </mat-card-footer>
        </mat-card>
        <mat-card *ngIf="!commission.certificateMetaDataId">
          <mat-card-content>
            <div class="headline">
              <span class="bold">
                <i class="fa-solid fa-triangle-exclamation missing-upload"></i>
                No Active Certificate
              </span>
            </div>
            <div class="body">Add a certificate to complete your commission.</div>
          </mat-card-content>
          <mat-card-footer>
            <mat-card-actions align="end">
              <button class="amr-btn-default"
                      (click)="openCertificateModal(selectedCommission, 'add')"
                      title="If disabled, please update the commission expiration date first."
                      [disabled]="isCommissionExpired">
                + Add Certificate
              </button>
            </mat-card-actions>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </div>
</div>
