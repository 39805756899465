<ng-container>
  <div class="ribbon-collection">
    <form [formGroup]="documentForm">
      <div class="ribbon-collection-items">
          <mat-form-field class="ribbon-item-heading">
            <input
                matInput
                formControlName="documentName"
                data-cy="documentName"/>
            <button class="btn ribbon-button-icon-default" matPrefix>
              <i class="fas fa-edit"></i>
            </button>
          </mat-form-field>          
        <div class="ribbon-item-group">
          <div class="ribbon-item-col">
            <div class="ribbon-item">
              <a
                class="nav-link-text float-end nav-link-text-bold"
                (click)="handleExitDocumentEditor()"
                ><span
                  class="fas fa-arrow-right-from-bracket ribbon-button-icon"
                ></span>
                Exit Document Editor</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="ribbon-collection-items">
        <div class="ribbon-item-group">
          <div class="ribbon-item-col">
            <div class="ribbon-item">
                <mat-form-field appearance="fill" class="clrsgn-form-field">
                  <mat-label for="documentType" class="clrsgn-label">Document Type</mat-label>
                  <input
                    matInput
                    maxlength="32"
                    formControlName="documentType"
                    data-cy="documentType"
                  />
                </mat-form-field>
            </div>
          </div>
          <div class="ribbon-item-col">
            <div class="ribbon-item">
                <mat-form-field appearance="fill" class="clrsgn-form-field">
                  <mat-label for="actionType" class="clrsgn-label">Action Type</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="actionType"
                    data-cy="actionType"
                  />
                </mat-form-field>
            </div>
          </div>
        </div>        
        <div class="ribbon-item-col float-end mark-as-reviewed">
          <div class="ribbon-item">
            <button
              *ngIf="requiresReview"
              class="btn center amr-btn-default"
              (click)="markAsReviewed()"              
              >
              Mark as Reviewed
            </button>
          </div>
          <div class="ribbon-item">
            <p *ngIf="reviewedBy">
              Reviewed By: {{ reviewedBy }}
          </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
