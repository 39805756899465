<div>
  <i
        routerLink="/myaccount/manage-commissions" 
        class="fa-solid fa-circle-arrow-left back-icon float-start"
        title="Back to Manage Commissions Page">
  </i>
</div>
<div class="page-header-bar">
  <section class="account-signatures">
    <h2>Account Signatures</h2>
    <button class="amr-btn-default action-btn filter-btn"
            data-backdrop="static"
            data-keyboard="false"
            (click)="openAddAccountSignatureModal('add')"
            >
      + Add Signature
    </button>
  </section>
  <div>View and manage your active Account Signatures</div>
</div>
<div>
  <div class="signatures">
    <div *ngFor="let accountSignature of signatures; let i = index;">
      <mat-card>
        <mat-card-header>
          <section>
            <img [src]="getSanitizedImage(accountSignature.signatureImageBase64)"
                 class="accountSignature-image"
                 alt="Account Signature Image '{{accountSignature.name}}'" />
          </section>
        </mat-card-header>
        <mat-card-content>
          <div class="headline">
            <span class="bold">Signature Name:</span> {{accountSignature.name}}
          </div>
        </mat-card-content>
        <mat-card-footer>
          <mat-card-actions align="end">
            <button (click)="deleteConfirmationDialog(accountSignature.accountSignatureId)"
                    class="amr-btn-outline">
              Delete
            </button>
            <button (click)="openEditAccountSignatureModal(accountSignature)"
                    class="amr-btn-default hide">
              Edit
            </button>
          </mat-card-actions>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
