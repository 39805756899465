<div class="twin-modal">
  <div class="add-account-signature">
    <div class="modal-header" *ngIf="mode == 'add'">
      Add Signature
    </div>
    <div class="modal-header" *ngIf="mode == 'edit'">
      Edit Signature
    </div>
    <ng-container>
      <div class="modal-body">
        <form [formGroup]="uploadSignatureForm" class="nex-form">
          <div class="row" *ngIf="signatures">
            <div class="col-12">
              <mat-form-field class="form-group">
                <mat-label for="accountSignature" class="clrsgn-label">Account Signature:</mat-label>
                <mat-select formControlName="accountSignature"
                            id="accountSignatures"
                            data-cy="accountSignatureDropdownList"
                            [multiple]="false">
                  <mat-option *ngIf="signatures" [value]="{}">-- Select a signature --</mat-option>
                  <mat-option *ngFor="let signature of signatures"
                              [value]="signature">
                    {{ signature?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Signature:<span class="explanation"> (Maximum File Size 21KB)</span></label>
                <app-file-upload id="signatureFileUpload" formControlName="signature" (change)="onFileSelected($event)"></app-file-upload>
                <app-form-error [group]="uploadSignatureForm"
                                [controlName]="'signature'"
                                [customErrorMessage]="'Please select a .PNG file'"></app-form-error>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ng-container *ngIf="(signatureUploaded | async) || hasSignature">
                <label>Signature Preview:</label> <br />
                <div class="signature-preview thin-border">
                  <img [src]="getSanitizedImage(selectedSignatureBase64)" class="signature-image" alt="" />
                </div>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container>
      <div class="modal-footer">
        <button type="button" class="amr-btn-outline-primary me-3" (click)="close()">Cancel</button>
        <button type="button" class="amr-btn-default" (click)="saveSignature()" *ngIf="mode == 'add'">Add Signature</button>
        <button type="button" class="amr-btn-default" (click)="saveSignature()" *ngIf="mode == 'edit'">Save</button>
      </div>
    </ng-container>
  </div>
  <div *ngIf="(signatureUploaded | async) || hasSignature" class="signature-preview-modal">
    <ng-container>
      <div>
        <app-signature-preview [signatureImage]="getSanitizedImage(selectedSignatureBase64)"></app-signature-preview>
      </div>
    </ng-container>
  </div>
</div>


